import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Circles } from "react-loader-spinner";
import { Stack } from "@mui/material";
import { exportMediaToCloud } from "utils/network.js";

const ExportDialog = ({ open, folderName, user, onClose }) => {
  const options = [
    "Audio Narratives",
    "Animation Videos",
    "Images",
    "Music",
    "Art",
  ];

  const [checkedState, setCheckedState] = useState(
    options.reduce((initialState, option) => {
      initialState[option] = false;
      return initialState;
    }, {})
  );

  const editorUrl = `${
    process.env.REACT_APP_REDIRECT_URL
  }editor?q=${encodeURIComponent(folderName)}-${encodeURIComponent(
    user.userId
  )}`;

  const [loader, setLoader] = useState(false);

  const [snackbarInfo, setSnackbarInfo] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const showSnackbar = (message, severity) => {
    setSnackbarInfo({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarInfo({ ...snackbarInfo, open: false });
  };

  const handleCheckboxChange = (option) => {
    setCheckedState({ ...checkedState, [option]: !checkedState[option] });
  };

  const isAnyOptionChecked = () => {
    return Object.values(checkedState).some((value) => value);
  };

  const handleSubmit = async () => {
    try {
      setLoader(true);
      setLoader(true);

      const payload = Object.entries(checkedState).reduce(
        (acc, [label, value]) => {
          if (value) acc[label] = true;
          return acc;
        },
        {}
      );

      const finalPayload = {
        media: payload,
        chatId: folderName,
        userId: user.userId,
      };

      const response = await exportMediaToCloud(finalPayload);

      if (response.status === 200) {
        showSnackbar("Export successful!", "success");
      }

      setLoader(false);
      setLoader(false);
    } catch (error) {
      console.error("Error submitting data:", error);
      showSnackbar("Failed to export. Please try again in sometime.", "error");
      setLoader(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            borderRadius: "20px",
            border: "1px solid rgba(255, 255, 255, 0.3)",
            boxShadow: "0 8px 32px rgba(0, 0, 0, 0.37)",
          },
        }}
      >
        <DialogContent
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            color: "white",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <span style={{ fontSize: "3rem" }}>Export Files</span>
            <Button
              onClick={onClose}
              sx={{
                color: "white",
                width: "40px",
                height: "40px",
                minWidth: "40px",
                minHeight: "40px",
                padding: "8px",
              }}
            >
              <CloseIcon sx={{ fontSize: "32px" }} />
            </Button>
          </Stack>
          <Stack spacing={2}>
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={checkedState[option]}
                    onChange={() => handleCheckboxChange(option)}
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "white",
                      },
                    }}
                  />
                }
                label={option}
                sx={{ color: "white", fontSize: "1.1rem" }}
              />
            ))}
          </Stack>
          <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: '10px' }}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(10px)",
                color: "white",
                border: "1px solid rgba(255, 255, 255, 0.3)",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "rgba(179, 160, 120, 0.2)",
                },
                fontSize: "16px",
                padding: "7px 20px",
              }}
            >
              {loader ? (
                <Circles
                  height="30"
                  width="30"
                  color="#011636"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                "EXPORT"
              )}
            </Button>

            <a
              href={editorUrl}
              target="_blank"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(10px)",
                color: "white",
                border: "1px solid rgba(255, 255, 255, 0.3)",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "rgba(179, 160, 120, 0.2)",
                },
                fontSize: "16px",
                padding: "10px 20px",
                textDecoration: "none",
                userSelect: "none",
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                msUserSelect: "none",
              }}
            >
              Go to DANKai Web
            </a>
          </div>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarInfo.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarInfo.severity}
          sx={{ width: "100%", fontSize: "16px" }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ExportDialog;
