import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ImageIcon from "@mui/icons-material/Image";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Typography from "@mui/material/Typography";

const OptionsMenu = ({
  messageId,
  handleImageGeneration,
  handleAdditionalImages,
  handleImageAnimations,
  handleVoiceGeneration,
  handleGPTResponseCopy,
  handleGPTResponseDownload,
  handleAnimeGeneration,
  index,
  imageModuleFlag,
  addonImageModuleFlag,
}) => {
  return (
    <Paper
      sx={{
        color: "white",
        ml: 3,
        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.10) inset",
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(129.36, 129.36, 129.36, 0.12) 49%, rgba(255, 255, 255, 0.12) 100%)"
      }}
    >
      <MenuList>
        <MenuItem onClick={() => handleGPTResponseCopy(index)}>
          <ListItemText>
            <Typography variant="body1" sx={{ fontSize: "12px" }}>
              Copy
            </Typography>
          </ListItemText>
          <ListItemIcon>
            <ContentCopyIcon sx={{ color: "white", marginLeft: "50px" }} />
          </ListItemIcon>
        </MenuItem>

        <Divider variant="middle" sx={{ background: "white" }} />
        <MenuItem onClick={() => handleGPTResponseDownload(index)}>
          <ListItemText>
            <Typography variant="body1" sx={{ fontSize: "12px" }}>
              Download As Docx
            </Typography>
          </ListItemText>
          <ListItemIcon>
            <FileDownloadIcon sx={{ color: "white", marginLeft: "50px" }} />
          </ListItemIcon>
        </MenuItem>

        <Divider variant="middle" sx={{ background: "white" }} />
        <MenuItem
          onClick={() => handleImageGeneration((prevState) => !prevState)}
        >
          <ListItemText>
            <Typography variant="body1" sx={{ fontSize: "12px" }}>
              Generate Image
            </Typography>
          </ListItemText>
          <ListItemIcon>
            <ImageIcon sx={{ color: "white", marginLeft: "50px" }} />
          </ListItemIcon>
        </MenuItem>

        {imageModuleFlag ? (
          <>
            <Divider variant="middle" sx={{ background: "white" }} />
            <MenuItem onClick={() => handleAdditionalImages(messageId)}>
              <ListItemText>
                <Typography variant="body1" sx={{ fontSize: "12px" }}>
                  Generate More Images
                </Typography>
              </ListItemText>
              <ListItemIcon>
                <ImageIcon sx={{ color: "white", marginLeft: "50px" }} />
              </ListItemIcon>
            </MenuItem>

            {addonImageModuleFlag ? (
              <>
                <Divider variant="middle" sx={{ background: "white" }} />
                <MenuItem onClick={() => handleImageAnimations(messageId)}>
                  <ListItemText>
                    <Typography variant="body1" sx={{ fontSize: "12px" }}>
                      Generate Image Animations
                    </Typography>
                  </ListItemText>
                  <ListItemIcon>
                    <OndemandVideoIcon
                      sx={{ color: "white", marginLeft: "50px" }}
                    />
                  </ListItemIcon>
                </MenuItem>

                <Divider variant="middle" sx={{ background: "white" }} />
                <MenuItem onClick={() => handleAnimeGeneration(messageId)}>
                  <ListItemText>
                    <Typography variant="body1" sx={{ fontSize: "12px" }}>
                      Animate From Text
                    </Typography>
                  </ListItemText>
                  <ListItemIcon>
                    <OndemandVideoIcon
                      sx={{ color: "white", marginLeft: "50px" }}
                    />
                  </ListItemIcon>
                </MenuItem>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        <Divider variant="middle" sx={{ background: "white" }} />
        <MenuItem onClick={() => handleVoiceGeneration(messageId)}>
          <ListItemText>
            <Typography variant="body1" sx={{ fontSize: "12px" }}>
              Generate Voice
            </Typography>
          </ListItemText>
          <ListItemIcon>
            <RecordVoiceOverIcon sx={{ color: "white", marginLeft: "50px" }} />
          </ListItemIcon>
        </MenuItem>
      </MenuList>
    </Paper>
  );
};

export default OptionsMenu;
