import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAllChatsByChatId, deleteAssetsFromProject } from "utils/network";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Tooltip,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SideDrawer from "components/Drawers/SideDrawer";
const ArtContents = () => {
  const { chatId, folderName } = useParams();
  const [images, setImages] = useState([]);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [hoveri, setHover] = useState(false);

  const toggleSideDrawer = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };

  useEffect(() => {
    async function fetchData() {
      const chatResponse = await getAllChatsByChatId(chatId);
      if (
        chatResponse.data &&
        chatResponse.data[0] &&
        chatResponse.data[0].artUrls
      ) {
        // console.log(chatResponse.data[0].artUrls); // Debugging to see the fetched art URLs
        setImages(chatResponse.data[0].artUrls);
      } else {
        setImages([]);
      }
    }
    fetchData();
  }, [chatId]);

  const handleDelete = async (url) => {
    const type = "art";
    await deleteAssetsFromProject(url, chatId, type);
    setImages((prevImages) => prevImages.filter((image) => image.url !== url));
  };

  return (
      <div>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
          sx={{
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}
          className="go-back"
        >
          <Link to={`/c/library/${chatId}`} style={{ color: "#FFFFFF" }}>
            <ArrowBackIcon sx={{ width: "2em", height: "3em" }} />
          </Link>
          <Typography
            variant="h2"
            component="div"
            sx={{ flexGrow: 1, color: "white" }}
          >
            Art
          </Typography>
          <IconButton
            aria-label="draw"
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 28 },
              color: "white",
              cursor: "pointer",
            }}
            onClick={toggleSideDrawer}
          >
            <PersonIcon />
          </IconButton>
        </Stack>
        <Grid
          container
          spacing={4}
          style={{
            paddingLeft: "2vw", // Adds left margin to the entire grid container
            paddingRight: "2vw",
            height: "calc(100vh - 80px)",
            paddingBottom: "16px",
            overflowY: "auto",
          }}
        >
          {images.length > 0 ? (
            images.map((image, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Paper
                  elevation={3}
                  style={{ position: "relative", backgroundColor: "#090336" }}
                >
                  <div
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    <IconButton
                      onClick={() => handleDelete(imageUrl)}
                      sx={{
                        zIndex: 1000,
                        position: "absolute",
                        left: 8,
                        top: 8,
                        color: (theme) => theme.palette.common.white,
                        backgroundColor: "rgba(0, 0, 255, 0.2)", // Semi-transparent blue background
                        border: "1px solid rgba(0, 0, 255, 0.3)", // Light blue border for a glass effect
                        borderRadius: "50%",
                        backdropFilter: "blur(10px)", // Blurring the background behind the button
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Optional: Adds subtle shadow
                        transition: "background-color 0.3s, border-color 0.3s", // Smooth transitions
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 255, 0.3)", // Slightly more opaque blue on hover
                          borderColor: "rgba(0, 0, 255, 0.5)", // Darker blue border on hover
                        },
                      }}
                    >
                      <DeleteIcon fontSize="large" />
                    </IconButton>
                  </div>
                  {hoveri ? (
                    <img
                      src={image?.url}
                      alt={`Image ${index}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        padding: "8px",
                        filter: "blur(5px)", // Apply blur effect
                        transition: "filter 0.3s ease", // Smooth transition for the blur effect
                      }}
                    />
                  ) : (
                    <img
                      src={image?.url}
                      alt={`Image ${index}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        padding: "8px",
                      }}
                    />
                  )}
                </Paper>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                color: "white",
              }}
            >
              <div>No Data!</div>
            </Grid>
          )}
        </Grid>

        <SideDrawer
          isOpen={sideDrawerOpen}
          onOpen={toggleSideDrawer}
          onClose={toggleSideDrawer}
        />
      </div>
  );
};

export default ArtContents;
