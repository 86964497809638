import axios from "axios";

const getToken = async (retryCount = 0) => {
  try {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser && "token" in storedUser) {
      return storedUser.token;
    } else if (retryCount < 3) {
      console.warn("Retrying to fetch token...");
      return getToken(retryCount + 1);
    }
    console.warn("No token found in localStorage.");
    return null;
  } catch (error) {
    console.error("Error retrieving token from localStorage:", error);
    return null;
  }
};

const getHeaders = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + (await getToken()),
  };
  return headers;
};

const apiEndpoint = process.env.REACT_APP_API_URL;

export const loginAuth = async (user) => {
  const url = `${apiEndpoint}login`;

  const response = await axios.post(url, user, { headers: await getHeaders() });

  return response;
};

export const sendImageRequest = async (
  prompt,
  userId,
  chatId,
  messageId,
  documentMode,
  aspectRatio,
  imageArtStyle,
  colorScheme,
  imageTone
) => {
  const url = `${apiEndpoint}img/imagine`;

  const response = await axios.post(
    url,
    {
      userId,
      chatId,
      messageId,
      prompt,
      documentMode,
      aspectRatio,
      imageArtStyle,
      colorScheme,
      imageTone,
    },
    {
      headers: await getHeaders(),
    }
  );

  return response;
};

export const sendImageRequestv2 = async (
  prompt,
  userId,
  chatId,
  aspectRatio,
  imageArtStyle,
  colorScheme,
  imageTone,
  seedValue,
  presetStyle
) => {
  const url = `${apiEndpoint}img/doc/imagine`;

  const response = await axios.post(
    url,
    {
      userId,
      chatId,
      prompt,
      aspectRatio,
      imageArtStyle,
      colorScheme,
      imageTone,
      seedValue,
      presetStyle,
    },
    {
      headers: await getHeaders(),
    }
  );

  return response;
};

export const sendImageRequestv3 = async (
  prompt,
  userId,
  chatId,
  messageId,
  documentUpload
) => {
  const url = `${apiEndpoint}img/text/imagine`;

  const response = await axios.post(
    url,
    {
      userId,
      chatId,
      messageId,
      documentUpload,
      prompt,
    },
    {
      headers: await getHeaders(),
    }
  );

  return response;
};

export const sendImageRequestv4 = async (
  images,
  userId,
  chatId,
  prompt,
  aspectRatio,
  imageArtStyle,
  colorScheme,
  imageTone
) => {
  const formData = new FormData();
  images.forEach((file, index) => formData.append(`images`, file));
  formData.append("userId", userId);
  formData.append("chatId", chatId);
  formData.append("prompt", prompt);
  formData.append("aspectRatio", aspectRatio);
  formData.append("imageArtStyle", imageArtStyle);
  formData.append("colorScheme", colorScheme);
  formData.append("imageTone", imageTone);

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}img/3/imagine`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + (await getToken()),
      },
    }
  );

  return response;
};

export const sendImageRequestv5 = async (
  prompt,
  userId,
  chatId,
  documentUpload,
  aspectRatio,
  imageArtStyle,
  colorScheme,
  imageTone,
  textStyle
) => {
  const url = `${apiEndpoint}img/3/text/imagine`;

  const response = await axios.post(
    url,
    {
      userId,
      chatId,
      documentUpload,
      prompt,
      aspectRatio,
      imageArtStyle,
      colorScheme,
      imageTone,
      textStyle
    },
    {
      headers: await getHeaders(),
    }
  );

  return response;
};


//creative upscale
export const sendImageRequestv6 = async (images, userId, chatId) => {
  const formData = new FormData();

  await Promise.all(
    images.map(async (image, index) => {
      if (typeof image === "string" && image.startsWith("blob:")) {
        const response = await fetch(image);
        const blob = await response.blob();
        const file = new File([blob], `image${index}.jpg`, { type: blob.type });
        formData.append("images", file);
      } else if (image instanceof File) {
        formData.append("images", image);
      }
    })
  );

  formData.append("userId", userId);
  formData.append("chatId", chatId);

  const response = await axios.post(
    `${apiEndpoint}img/ops/u/1`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + (await getToken()),
      },
    }
  );

  return response;
};

// conservative upscale
export const sendImageRequestv7 = async (images, userId, chatId) => {
  const formData = new FormData();

  await Promise.all(
    images.map(async (image, index) => {
      if (typeof image === "string" && image.startsWith("blob:")) {
        const response = await fetch(image);
        const blob = await response.blob();
        const file = new File([blob], `image${index}.jpg`, { type: blob.type });
        formData.append("images", file);
      } else if (image instanceof File) {
        formData.append("images", image);
      }
    })
  );

  formData.append("userId", userId);
  formData.append("chatId", chatId);

  const response = await axios.post(
    `${apiEndpoint}img/ops/u/2`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + (await getToken()),
      },
    }
  );

  return response;
};
//remove bg
export const sendImageRequestv8 = async (images, userId, chatId) => {
  const formData = new FormData();

  await Promise.all(
    images.map(async (image, index) => {
      if (typeof image === "string" && image.startsWith("blob:")) {
        const response = await fetch(image);
        const blob = await response.blob();
        const file = new File([blob], `image${index}.jpg`, { type: blob.type });
        formData.append("images", file);
      } else if (image instanceof File) {
        formData.append("images", image);
      }
    })
  );

  formData.append("userId", userId);
  formData.append("chatId", chatId);

  const response = await axios.post(
    `${apiEndpoint}img/ops/rbg`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + (await getToken()),
      },
    }
  );

  return response;
};

//outpaint
export const sendImageRequestv9 = async (
  images,
  userId,
  chatId,
  outpaintValues
) => {
  const formData = new FormData();

  await Promise.all(
    images.map(async (image, index) => {
      if (typeof image === "string" && image.startsWith("blob:")) {
        const response = await fetch(image);
        const blob = await response.blob();
        const file = new File([blob], `image${index}.jpg`, { type: blob.type });
        formData.append("images", file);
      } else if (image instanceof File) {
        formData.append("images", image);
      }
    })
  );

  formData.append("userId", userId);
  formData.append("chatId", chatId);
  formData.append("right", outpaintValues.right);
  formData.append("left", outpaintValues.left);
  formData.append("top", outpaintValues.top);
  formData.append("bottom", outpaintValues.bottom);

  const response = await axios.post(
    `${apiEndpoint}img/ops/op`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + (await getToken()),
      },
    }
  );

  return response;
};


export const sendAddonImageRequest = async (
  prompt,
  imageRef,
  userId,
  chatId,
  messageId
) => {
  const url = `${apiEndpoint}img/additional/imagine`;

  const response = await axios.post(
    url,
    {
      userId,
      chatId,
      messageId,
      imageRef,
      prompt,
    },
    {
      headers: await getHeaders(),
    }
  );

  return response;
};

export const getImageAnimations = async (userId, chatId, messageId) => {
  const url = `${apiEndpoint}img/imagine/video`;

  const response = await axios.post(
    url,
    { chatId: chatId, messageId: messageId, userId: userId },
    { headers: await getHeaders() }
  );

  return response;
};

export const getImageRequestProgress = async (imageId, id, msgId, dm, uid) => {
  const url = `${apiEndpoint}img/message/${imageId}?id=${id}&msgId=${msgId}&dm=${dm}&uid=${uid}`;

  const response = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + (await getToken()),
    },
  });

  return response;
};

export const getSelectedImage = async (
  userId,
  chatId,
  messageId,
  documentMode,
  imagineId,
  button,
  jobId
) => {
  const url = `${apiEndpoint}img/button`;

  const response = await axios.post(
    url,
    {
      userId,
      chatId,
      messageId,
      documentMode,
      imagineId,
      button,
      jobId,
    },
    {
      headers: await getHeaders(),
    }
  );

  return response;
};

export const generateVoice = async (params) => {
  const url = `${apiEndpoint}text-to-speech`;

  const { text, voice, widgetId, isMultilingual } = params;

  const response = await axios.post(
    url,
    {
      prompt: text,
      voice: voice,
      widgetId: widgetId,
      isMultilingual: isMultilingual,
    },
    {
      headers: await getHeaders(),
    }
  );

  return response;
};


export const generateCustomVoice = async (prompt, text) => {
  const url = `${apiEndpoint}voice/custom`;

  const response = await axios.post(
    url,
    {
      prompt,
      text
    },
    {
      headers: await getHeaders(),
    }
  );

  return response;

};
export const getVoiceBytes = async (widgetId, messageId, chatId) => {
  const url = `${apiEndpoint}text-to-speech/fetch-audio-stream/${widgetId}?id=${chatId}&msgId=${messageId}`;

  const response = await axios.get(url, {
    responseType: "arraybuffer",
    headers: {
      Authorization: "Bearer " + (await getToken()),
    },
  });

  return response;
};

export const getBackgroundMusic = async (payload) => {
  const url = `${apiEndpoint}generate-prompt/music`;

  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });

  return response;
};

export const getMusicUrl = async (originalPrompt,prompt, userId, chatId) => {
  const url = `${apiEndpoint}generate-music`;

  const response = await axios.post(
    url,
    { originalPrompt: originalPrompt, prompt: prompt, userId: userId, chatId: chatId },
    { headers: await getHeaders() }
  );

  return response;
};

export const getSoundEffectsUrl = async (prompt, duration, userId, chatId) => {
  const url = `${apiEndpoint}generate-effects`;

  const response = await axios.post(
    url,
    {
      prompt: prompt,
      endTimeInSeconds: duration,
      userId: userId,
      chatId: chatId,
    },
    { headers: await getHeaders() }
  );

  return response;
};

export const getSoundEffectsFromVideo = async (prompt, duration, userId, chatId, videos) => {
  const url = `${apiEndpoint}video-to-sfx`;

  // Create a new FormData object
  const formData = new FormData();
  formData.append('prompt', prompt);
  formData.append('endTimeInSeconds', duration);
  formData.append('userId', userId);
  formData.append('chatId', chatId);
  videos.forEach((file, index) => formData.append(`video`, file));


  const response = await axios.post(
    url,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + (await getToken()),
      },
    }
  );

  return response;
};

export const getPromptsFromEngine = async (payload) => {
  const url = `${apiEndpoint}generate-prompt/prompt`;

  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });

  return response;
};

export const getEduPromptsFromEngine = async (payload) => {
  const url = `${apiEndpoint}generate-prompt/edu/prompt`;

  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });

  return response;
};

export const getAnimatedVideo = async (
  prompt,
  userId,
  messageId,
  chatId,
  videoAspectRatio,
  animationStyle,
  animationMoods,
  colorAndLighting
) => {
  const url = `${apiEndpoint}animation`;

  const response = await axios.post(
    url,
    {
      prompt,
      chatId,
      messageId,
      userId,
      videoAspectRatio,
      animationStyle,
      animationMoods,
      colorAndLighting,
    },
    { headers: await getHeaders() }
  );

  return response;
};

export const getDirectAnimatedVideo = async (
  prompt,
  userId,
  chatId,
  videoAspectRatio,
  animationStyle,
  animationMoods,
  colorAndLighting,
  videoUrl,
  regenFlag,
  selectedVideoMotionModel
) => {
  const url = `${apiEndpoint}direct-animation`;

  const response = await axios.post(
    url,
    {
      prompt,
      chatId,
      userId,
      videoAspectRatio,
      animationStyle,
      animationMoods,
      colorAndLighting,
      url: videoUrl, // old url
      regenFlag,
      model: selectedVideoMotionModel
    },
    { headers: await getHeaders() }
  );

  return response;
};

export const getAllChats = async (id) => {
  const url = `${apiEndpoint}chat/get?userId=${id}`;

  const response = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + (await getToken()),
    },
  });

  return response;
};

export const getAllChatsByChatId = async (id) => {
  const url = `${apiEndpoint}chat/get?id=${id}`;

  const response = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + (await getToken()),
    },
  });

  return response;
};

export const getAllTransformedChatsByChatId = async (id) => {
  const url = `${apiEndpoint}chat/get?id=${id}&t=1`;

  const response = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + (await getToken()),
    },
  });

  return response;
};

export const getFileName = async (id) => {
  const url = `${apiEndpoint}/get-files?chatId=${id}`;

  const response = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + (await getToken()),
    },
  });

  return response;
};

export const getSignedUrl = async (files) => {
  let urls = [];

  await Promise.all(
    files.map(async (file) => {
      const url = `${apiEndpoint}get-signed-url?fileName=${file.filename}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + (await getToken()),
        },
      });

      urls.push(response?.data?.url);
    })
  );

  return urls;
};

export const exportMediaToCloud = async (payload) => {
  const url = `${apiEndpoint}export-media`;

  const response = await axios.post(url, payload, {
    headers: await getHeaders(),
  });
  return response;
};

export const deleteMessageFromChat = async (payload) => {
  const url = `${apiEndpoint}chat/delete-message`;

  const response = await axios.delete(url, {
    headers: await getHeaders(),
    data: payload,
  });
  return response;
};

export const deleteChat = async (payload) => {
  const url = `${apiEndpoint}chat/delete`;

  const response = await axios.delete(url, {
    headers: await getHeaders(),
    data: payload,
  });
  return response;
};

export const getImagesDirectly = async (
  images,
  userId,
  chatId,
  prompt,
  aspectRatio,
  imageArtStyle,
  colorScheme,
  imageTone
) => {
  const formData = new FormData();
  images.forEach((file, index) => formData.append(`images`, file));
  formData.append("userId", userId);
  formData.append("chatId", chatId);
  formData.append("prompt", prompt);
  formData.append("aspectRatio", aspectRatio);
  formData.append("imageArtStyle", imageArtStyle);
  formData.append("colorScheme", colorScheme);
  formData.append("imageTone", imageTone);

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}img/direct/imagine`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + (await getToken()),
      },
    }
  );

  return response;
};

export const getImagesDirectlyWithCSRF = async (
  images,
  userId,
  chatId,
  prompt,
  aspectRatio,
  imageArtStyle,
  colorScheme,
  imageTone
) => {
  const formData = new FormData();
  images.forEach((file, index) => formData.append(`images`, file));
  formData.append("userId", userId);
  formData.append("chatId", chatId);
  formData.append("prompt", prompt);
  formData.append("aspectRatio", aspectRatio);
  formData.append("imageArtStyle", imageArtStyle);
  formData.append("colorScheme", colorScheme);
  formData.append("imageTone", imageTone);

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}img/direct/crf`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + (await getToken()),
      },
    }
  );

  return response;
};

export const getAnimationsDirectly = async (
  images,
  userId,
  chatId,
  prompt,
  uploadType,
  videoAspectRatio,
  animationStyle,
  colorAndLighting,
  animationMoods,
  motionStrength,
  motionModel
) => {
  const formData = new FormData();

  if (uploadType === "file") {
    images.forEach((file, index) => formData.append(`images`, file));
  } else if (uploadType === "url") {
    const imageResponse = await axios.get(images, { responseType: "blob" });
    const imageBlob = imageResponse.data;
    const fileName =
      typeof images === "string"
        ? images.split("/").pop()
        : images[0].split("/").pop();
    formData.append(`images`, imageBlob, fileName);
  }

  formData.append("userId", userId);
  formData.append("chatId", chatId);
  formData.append("prompt", prompt);
  formData.append("aspectRatio", videoAspectRatio);
  formData.append("animationStyle", animationStyle);
  formData.append("colorAndLighting", colorAndLighting);
  formData.append("animationMoods", animationMoods);
  formData.append("motionStrength", motionStrength);
  formData.append("model", motionModel);

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}img/direct/animate`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + (await getToken()),
      },
    }
  );

  return response;
};

export const getRegeneratedImage = async (url, userId, chatId, prompt) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}img/direct/imagine`,
    {
      url,
      userId,
      chatId,
      prompt,
      regenFlag: true,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + (await getToken()),
      },
    }
  );

  return response;
};

export const deleteAssetsFromProject = async (url, chatId, type) => {
  const apiUrl = `${apiEndpoint}chat/delete-assets`;
  const payload = {
    url,
    chatId,
    type,
  };

  const response = await axios.delete(apiUrl, {
    headers: await getHeaders(),
    data: payload,
  });

  return response;
};

export const renameProject = async (payload) => {
  const apiUrl = `${apiEndpoint}chat/update/name`;

  const response = await axios.put(apiUrl, payload, {
    headers: await getHeaders(),
  });

  return response;
}

export const deleteManyProjects = async (payload) => {
  const apiUrl = `${apiEndpoint}chat/m/delete`;

  const response = await axios.delete(apiUrl, {
    headers: await getHeaders(),
    data: payload,
  });

  return response;
}