import { useState, useEffect } from "react";
import {
  List,
  ListItem,
  FormControl,
  Typography,
  TextField,
  Stack,
  Button,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  genres,
  tones,
  personalities,
  writing_styles,
  persona,
  educational_persona,
  entertainment_persona,
  educational_persona_mapping,
  entertainment_persona_mapping,
} from "utils/constants";

const PersonaHandler = ({
  onPersonaChange,
  onGenreChange,
  onWritingsChange,
  onPersonalityChange,
  onToneChange,
  onCategoryChange,
  currentPersona,
  setNewMessage,
  selectedCategory,
  onSubmit,
}) => {
  const [selectedTone, setSelectedTone] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("");
  const [selectedPersonality, setSelectedPersonality] = useState("");
  const [selectedWritingStyles, setSelectedWritingStyles] = useState("");
  const [selectedPersona, setSelectedPersona] = useState(
    currentPersona ? currentPersona : ""
  );
  const [prompt, setPrompt] = useState("");
  const [mappingType, setMappingType] = useState(
    selectedCategory ? selectedCategory : "entertainment"
  );

  useEffect(() => {
    try {
      const persona_now = currentPersona;
      const mapping_object = getMappingObject(mappingType, persona_now);

      setSelectedWritingStyles(mapping_object["writing-style"]);
      setSelectedPersonality(mapping_object.personality);
      setSelectedGenre(mapping_object.genre);
      setSelectedTone(mapping_object.tone);
    } catch (error) {
      console.error(error);
    }
  }, [mappingType]);

  const getMappingObject = (type, persona) => {
    if (type === "education") {
      return educational_persona_mapping[persona];
    } else {
      return entertainment_persona_mapping[persona];
    }
  };

  const handlePersonaChange = (event, newValue) => {
    try {
      setSelectedPersona(newValue);
      const mapping_object = getMappingObject(mappingType, newValue);

      setSelectedWritingStyles(mapping_object["writing-style"]);
      setSelectedPersonality(mapping_object.personality);
      setSelectedGenre(mapping_object.genre);
      setSelectedTone(mapping_object.tone);

      onPersonaChange(newValue);
    } catch (error) {
      console.error(error);
    }
  };

  const handleWritingStylesChange = (event, newValue) => {
    setSelectedWritingStyles(newValue);
    onWritingsChange(newValue);
  };

  const handlePersonalityChange = (event, newValue) => {
    setSelectedPersonality(newValue);
    onPersonalityChange(newValue);
  };

  const handleGenreChange = (event, newValue) => {
    setSelectedGenre(newValue);
    onGenreChange(newValue);
  };

  const handleToneChange = (event, newValue) => {
    setSelectedTone(newValue);
    onToneChange(newValue);
  };

  const handlePrompt = (event) => {
    setPrompt(event.target.value);
    setNewMessage(event.target.value);
    console.log(event.target.value);
  };

  const handleMappingTypeChange = (type) => {
    setMappingType(type);
    onCategoryChange(type);
  };

  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        flexWrap="wrap"
        spacing={{ xs: 1, sm: 1, md: 2 }}
        useFlexGap
        sx={{ marginBottom: 2 }}
      >
        <Button
          variant={mappingType === "entertainment" ? "contained" : "outlined"}
          style={{
            border: "1px solid #FFFFFF",
            backgroundColor:
              mappingType === "entertainment" ? "#FFFFFF" : "transparent",
            color: mappingType === "entertainment" ? "#011636" : "#FFFFFF",
            fontSize: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            borderRadius: "20px",
            "&:hover": {
              backgroundColor: "#FFFFFF",
              borderColor: "#FFFFFF",
              color: "#011636",
              opacity: 0.9,
            },
          }}
          onClick={() => handleMappingTypeChange("entertainment")}
        >
          Entertainment
        </Button>
        <Button
          variant={mappingType === "education" ? "contained" : "outlined"}
          style={{
            border: "1px solid #FFFFFF",
            backgroundColor:
              mappingType === "education" ? "#FFFFFF" : "transparent",
            color: mappingType === "education" ? "#011636" : "#FFFFFF",
            fontSize: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            borderRadius: "20px",
            "&:hover": {
              backgroundColor: "#FFFFFF",
              borderColor: "#FFFFFF",
              color: "#011636",
              opacity: 0.9,
            },
          }}
          onClick={() => handleMappingTypeChange("education")}
        >
          Education
        </Button>
      </Stack>
      <List>
        <ListItem
          sx={{
            display: "flex",
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "center",
              xl: "center",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "200px",
                xl: "200px",
              },
              marginRight: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            Prompt
          </Typography>
          <FormControl fullWidth sx={{ flex: 1, marginRight: "16px" }}>
            <TextField
              placeholder="5000 characters only..."
              style={{ color: "white" }}
              InputLabelProps={{
                style: { color: "#FFFFFF", fontSize: "18px" },
              }}
              InputProps={{ style: { color: "#FFFFFF", fontSize: "18px" } }}
              sx={{
                "& .MuiInputBase-input": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                  fontSize: "1.25rem", // Increase the font size of the placeholder
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "white",
                  fontSize: "1.25rem", // Increase the font size of the placeholder when focused
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiInputBase-root": {
                  color: "white",
                },
              }}
              value={prompt}
              onChange={handlePrompt}
              multiline
              maxRows={10}
              inputProps={{ maxLength: 5000 }}
            />
          </FormControl>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "center",
              xl: "center",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "200px",
                xl: "200px",
              },
              marginRight: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            Persona
          </Typography>
          <FormControl fullWidth sx={{ flex: 1, marginRight: "16px" }}>
            <Autocomplete
              value={selectedPersona}
              onChange={handlePersonaChange}
              options={
                mappingType === "education"
                  ? educational_persona
                  : entertainment_persona
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search..."
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ color: "white", fontSize: "2.5rem" }}
                        />
                      </InputAdornment>
                    ),
                    style: {
                      color: "white",
                      fontSize: "2rem", // Increase the font size of the input text
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "white",
                      fontSize: "2rem", // Increase the font size of the placeholder text
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                      fontSize: "1.25rem", // Increase the font size of the placeholder
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "white",
                      fontSize: "1.25rem", // Increase the font size of the placeholder when focused
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                    "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                      color: "white",
                    },
                    "& .MuiInputBase-root": {
                      color: "white",
                    },
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                    color: "black", // Set text color for the options
                    "&[aria-selected='true']": {
                      backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                    },
                  },
                },
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "center",
              xl: "center",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "200px",
                xl: "200px",
              },
              marginRight: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            Genre
          </Typography>
          <FormControl fullWidth sx={{ flex: 1, marginRight: "16px" }}>
            <Autocomplete
              value={selectedGenre}
              onChange={handleGenreChange}
              options={genres}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search..."
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ color: "white", fontSize: "2.5rem" }}
                        />
                      </InputAdornment>
                    ),
                    style: {
                      color: "white",
                      fontSize: "2rem", // Increase the font size of the input text
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "white",
                      fontSize: "2rem", // Increase the font size of the placeholder text
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                      fontSize: "1.25rem", // Increase the font size of the placeholder
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "white",
                      fontSize: "1.25rem", // Increase the font size of the placeholder when focused
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                    "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                      color: "white",
                    },
                    "& .MuiInputBase-root": {
                      color: "white",
                    },
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                    color: "black", // Set text color for the options
                    "&[aria-selected='true']": {
                      backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                    },
                  },
                },
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "center",
              xl: "center",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: {
                XS: "100%",
                sm: "100%",
                md: "100%",
                lg: "200px",
                xl: "200px",
              },
              marginRight: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            Tone
          </Typography>
          <FormControl fullWidth sx={{ flex: 1, marginRight: "16px" }}>
            <Autocomplete
              value={selectedTone}
              onChange={handleToneChange}
              options={tones}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search..."
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ color: "white", fontSize: "2.5rem" }}
                        />
                      </InputAdornment>
                    ),
                    style: {
                      color: "white",
                      fontSize: "2rem", // Increase the font size of the input text
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "white",
                      fontSize: "2rem", // Increase the font size of the placeholder text
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                      fontSize: "1.25rem", // Increase the font size of the placeholder
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "white",
                      fontSize: "1.25rem", // Increase the font size of the placeholder when focused
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                    "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                      color: "white",
                    },
                    "& .MuiInputBase-root": {
                      color: "white",
                    },
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                    color: "black", // Set text color for the options
                    "&[aria-selected='true']": {
                      backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                    },
                  },
                },
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "center",
              xl: "center",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "200px",
                xl: "200px",
              },
              marginRight: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            Personality
          </Typography>
          <FormControl fullWidth sx={{ flex: 1, marginRight: "16px" }}>
            <Autocomplete
              value={selectedPersonality}
              onChange={handlePersonalityChange}
              options={personalities}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search..."
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ color: "white", fontSize: "2.5rem" }}
                        />
                      </InputAdornment>
                    ),
                    style: {
                      color: "white",
                      fontSize: "2rem", // Increase the font size of the input text
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "white",
                      fontSize: "2rem", // Increase the font size of the placeholder text
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                      fontSize: "1.25rem", // Increase the font size of the placeholder
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "white",
                      fontSize: "1.25rem", // Increase the font size of the placeholder when focused
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                    "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                      color: "white",
                    },
                    "& .MuiInputBase-root": {
                      color: "white",
                    },
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                    color: "black", // Set text color for the options
                    "&[aria-selected='true']": {
                      backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                    },
                  },
                },
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "center",
              xl: "center",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "200px",
                xl: "200px",
              },
              marginRight: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            Writing Style
          </Typography>
          <FormControl fullWidth sx={{ flex: 1, marginRight: "16px" }}>
            <Autocomplete
              value={selectedWritingStyles}
              onChange={handleWritingStylesChange}
              options={writing_styles}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search..."
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ color: "white", fontSize: "2.5rem" }}
                        />
                      </InputAdornment>
                    ),
                    style: {
                      color: "white",
                      fontSize: "2rem", // Increase the font size of the input text
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "white",
                      fontSize: "2rem", // Increase the font size of the placeholder text
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                      fontSize: "1.25rem", // Increase the font size of the placeholder
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "white",
                      fontSize: "1.25rem", // Increase the font size of the placeholder when focused
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                    "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                      color: "white",
                    },
                    "& .MuiInputBase-root": {
                      color: "white",
                    },
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                    color: "black", // Set text color for the options
                    "&[aria-selected='true']": {
                      backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                    },
                  },
                },
              }}
            />
          </FormControl>
        </ListItem>
      </List>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        flexWrap="wrap"
        spacing={{ xs: 1, sm: 1, md: 2 }}
        useFlexGap
      >
        <Button
          id="basic-button"
          variant="outlined"
          aria-haspopup="true"
          size="large"
          sx={{
            border: "1px solid white",
            borderRadius: "20px",
            backgroundColor: "transparent",
            color: "white",
            fontSize: "16px",
            marginTop: "16px",
            "&:hover": {
              backgroundColor: "white",
              color: "#011636",
            },
          }}
          onClick={() => {
            handleMappingTypeChange(mappingType);
            onSubmit();
          }}
        >
          Generate
        </Button>
      </Stack>
    </div>
  );
};

export default PersonaHandler;
