import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography, Stack, AppBar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SideDrawer from "components/Drawers/SideDrawer";
import Logo from "assets/images/Logo.png";
import "./AppBar.css";

const TopAppBar = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const toggleSideDrawer = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };

  return (
    <>
      <AppBar position="static" className="appBar">
        <Stack direction="row" spacing={2} alignItems="center">
          <Box sx={{ zIndex: 1200 }}>
            <Link to="/dashboard">
              <IconButton aria-label="back">
                <ArrowBackIcon
                  style={{ width: "2em", height: "2em", color: "white" }}
                />
              </IconButton>
            </Link>
          </Box>
          <img src={Logo} alt="image" className="logoImage" />
          <Typography
            variant="h4"
            component="div"
            sx={{ color: "white", flexGrow: 1 }}
          >
            Compositional AI
          </Typography>
          <IconButton
            aria-label="draw"
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 28 },
              color: "white",
              cursor: "pointer",
            }}
            onClick={toggleSideDrawer}
          >
            <PersonIcon />
          </IconButton>
        </Stack>
      </AppBar>
      <SideDrawer
        isOpen={sideDrawerOpen}
        onOpen={toggleSideDrawer}
        onClose={toggleSideDrawer}
      />
    </>
  );
};

export default TopAppBar;
