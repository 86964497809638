import { useRef, useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Stack,
  TextField,
  CircularProgress,
} from "@mui/material";
import CustomSnackbar from "components/Common/Snackbar/Snackbar";
import useSnackbar from "hooks/Snackbar";
import { generateCustomVoice } from "utils/network";
import { AudioPlayerWithoutDownload } from "components/AudioPlayer/AudioPlayer";

const VoiceSettingsDrawer = ({
  voice,
  voiceDisplay,
  anchorEl,
  setAnchorEl,
  open,
  handleClose,
  setVoice,
  setVoiceDisplay,
  mappedVoices,
  inputValue,
  onChangeInput,
  onSubmit,
}) => {
  const buttonRef = useRef(null);
  const [view, setView] = useState("dialogue");
  const [prompt, setPrompt] = useState("");
  const [text, setText] = useState("");
  const [customVoice, setCustomVoice] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPromptError, setShowPromptError] = useState(false);
  const [showTextError, setShowTextError] = useState(false);
  const [customVoiceName, setCustomVoiceName] = useState("");
  const [showNameInput, setShowNameInput] = useState(false);

  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarSeverity,
    snackbarContent,
    showSnackbar,
  } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggle = (viewName) => {
    setView(viewName);
  };

  const handleUseCustomVoice = () => {
    setShowNameInput(true);
  };

  const handleCustomVoiceSelect = () => {
    if (customVoiceName) {
      const newVoice = { voice: customVoice.voiceId, display: `Custom Voice : ${customVoiceName}` };
      setVoiceDisplay(`Custom Voice : ${customVoiceName}`);
      setVoice(customVoice.voiceId);
      mappedVoices.unshift(newVoice); // Push new voice to the top
      setShowNameInput(false);
      setAnchorEl(null);
      setView("dialogue");
      setCustomVoiceName("");
    }
  };

  const validateInput = (input, minLength) => input.length >= minLength;

  const handleGenerateVoice = () => {
    setLoading(true);
    generateCustomVoice(prompt, text)
      .then((response) => {
        setCustomVoice(response.data);
        setShowPromptError(false);
        setShowTextError(false);
      })
      .catch((error) => {
        if (!validateInput(prompt, 20)) setShowPromptError(true);
        if (!validateInput(text, 100)) setShowTextError(true);
        console.error(error);
        showSnackbar("error", error.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePromptChange = (e) => {
    const newPrompt = e.target.value;
    setPrompt(newPrompt);
    if (validateInput(newPrompt, 20)) {
      setShowPromptError(false);
    }
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    if (validateInput(newText, 100)) {
      setShowTextError(false);
    }
  };

  return (
    <Stack direction="column" spacing={3} alignItems="center">
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button
          variant={view === "dialogue" ? "contained" : "outlined"}
          onClick={() => handleToggle("dialogue")}
          sx={{
            alignSelf: "center",
            backgroundColor: view === "dialogue" ? "white" : "transparent",
            border: "2px solid white",
            borderRadius: 10,
            color: view === "dialogue" ? "#011636" : "white",
            fontSize: "16px",
            "&:hover": {
              backgroundColor: "white",
              borderColor: "white",
              color: "#011636",
              opacity: 0.9,
            },
          }}
        >
          AI Voice Personas
        </Button>
        <Button
          variant={view === "voice" ? "contained" : "outlined"}
          onClick={() => handleToggle("voice")}
          sx={{
            alignSelf: "center",
            backgroundColor: view === "voice" ? "white" : "transparent",
            border: "2px solid white",
            borderRadius: 10,
            color: view === "voice" ? "#011636" : "white",
            fontSize: "16px",
            "&:hover": {
              backgroundColor: "white",
              borderColor: "white",
              color: "#011636",
              opacity: 0.9,
            },
          }}
        >
          Custom Voice Gen
        </Button>
      </Stack>

      {view === "dialogue" ? (
        <>
          <TextField
            label="Text"
            variant="outlined"
            value={inputValue}
            onChange={onChangeInput}
            fullWidth
            InputLabelProps={{
              style: { color: "white", fontSize: "18px" },
            }}
            InputProps={{
              style: {
                color: "white",
                fontSize: "18px",
              },
            }}
            sx={{
              "& label.Mui-focused": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              "& .MuiOutlinedInput-input": {
                paddingTop: "14px",
                paddingBottom: "14px",
              },
              "& .MuiInputLabel-outlined": {
                transform: "translate(14px, 14px) scale(1)",
              },
              "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
            }}
            multiline
            maxRows={15}
          />

          <Button
            id="voice-button"
            variant="contained"
            aria-haspopup="true"
            size="large"
            onClick={handleClick}
            ref={buttonRef}
            sx={{
              backgroundColor: "transparent",
              color: "white",
              width: "100%",
              alignSelf: "center",
              border: "1px solid white",
              borderRadius: 2,
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "white",
                opacity: 0.9,
              },
            }}
          >
            {voiceDisplay || "Voices"}
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            MenuListProps={{
              "aria-labelledby": "voice-button",
            }}
            PaperProps={{
              style: {
                backgroundColor: "white",
                color: "black",
                width: buttonRef.current
                  ? buttonRef.current.offsetWidth
                  : "auto",
                maxHeight: 200,
                overflowY: "auto",
                borderRadius: "8px",
              },
            }}
            sx={{
              ".MuiMenuItem-root": {
                fontSize: "1.5rem",
              },
              ".MuiSelect-select": {
                fontSize: "1.5rem",
              },
            }}
          >
            {mappedVoices.map((voice, index) => (
              <MenuItem
                onClick={handleClose}
                key={index}
                id={voice.voice}
                sx={{
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                {voice.display}
              </MenuItem>
            ))}
          </Menu>

          <Button
            id="generate-button"
            variant="contained"
            size="large"
            onClick={onSubmit}
            sx={{
              alignSelf: "center",
              backgroundColor: "transparent",
              border: "2px solid white",
              borderRadius: 10,
              color: "white",
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "white",
                borderColor: "white",
                color: "#011636",
                opacity: 0.9,
              },
            }}
          >
            Generate
          </Button>
        </>
      ) : (
        <>
          <TextField
            label="Voice description"
            placeholder="Enter a description for your voice in more than 20 characters"
            variant="outlined"
            value={prompt}
            onChange={handlePromptChange}
            fullWidth
            error={showPromptError}
            helperText={
              showPromptError
                ? "'Voice description' must contain at least 20 characters."
                : ""
            }
            InputLabelProps={{
              style: { color: "white", fontSize: "18px" },
            }}
            InputProps={{
              style: {
                color: "white",
                fontSize: "18px",
              },
            }}
            sx={{
              "& label.Mui-focused": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": {
                  borderColor: showPromptError ? "red" : "white",
                },
                "&:hover fieldset": {
                  borderColor: showPromptError ? "red" : "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: showPromptError ? "red" : "white",
                },
              },
            }}
            FormHelperTextProps={{
              sx: { fontSize: "16px" },
            }}
            multiline
            maxRows={15}
          />

          <TextField
            label="Text"
            placeholder="Enter text here to generate a custom voice"
            variant="outlined"
            value={text}
            onChange={handleTextChange}
            fullWidth
            error={showTextError}
            helperText={
              showTextError
                ? "'Text' must contain at least 100 characters."
                : ""
            }
            InputLabelProps={{
              style: { color: "white", fontSize: "18px" },
            }}
            InputProps={{
              style: {
                color: "white",
                fontSize: "18px",
              },
            }}
            sx={{
              "& label.Mui-focused": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": {
                  borderColor: showTextError ? "red" : "white",
                },
                "&:hover fieldset": {
                  borderColor: showTextError ? "red" : "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: showTextError ? "red" : "white",
                },
              },
            }}
            FormHelperTextProps={{
              sx: { fontSize: "16px" },
            }}
            multiline
            maxRows={15}
          />

          {customVoice && customVoice.audio && (
            <Stack direction="row" spacing={2}>
              <AudioPlayerWithoutDownload audioData={customVoice.audio} />
              <Button
                variant="contained"
                size="large"
                sx={{
                  alignSelf: "center",
                  backgroundColor: "transparent",
                  border: "2px solid white",
                  borderRadius: 10,
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "white",
                    borderColor: "white",
                    color: "#011636",
                    opacity: 0.9,
                  },
                }}
                onClick={handleUseCustomVoice}
              >
                Use Voice
              </Button>
              <Button
                variant="contained"
                size="large"
                sx={{
                  alignSelf: "center",
                  backgroundColor: "transparent",
                  border: "2px solid white",
                  borderRadius: 10,
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "white",
                    borderColor: "white",
                    color: "#011636",
                    opacity: 0.9,
                  },
                }}
                onClick={handleGenerateVoice}
                disabled={!prompt || !text}
              >
                Regenerate
              </Button>
            </Stack>
          )}

          {showNameInput && (
            <Stack direction="row" spacing={2}>
              <TextField
                label="Custom Voice Name"
                placeholder="Name the voice in 20 characters"
                variant="outlined"
                value={customVoiceName}
                onChange={(e) => setCustomVoiceName(e.target.value)}
                fullWidth
                InputLabelProps={{
                  style: { color: "white", fontSize: "18px" },
                }}
                InputProps={{
                  style: {
                    color: "white",
                    fontSize: "18px",
                  },
                }}
                sx={{
                  "& label.Mui-focused": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
                FormHelperTextProps={{
                  sx: { fontSize: "16px" },
                }}
              />
              <Button
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: "transparent",
                  border: "2px solid white",
                  borderRadius: 10,
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "white",
                    borderColor: "white",
                    color: "#011636",
                    opacity: 0.9,
                  },
                }}
                onClick={handleCustomVoiceSelect}
              >
                Save
              </Button>
            </Stack>
          )}

          {loading ? (
            <CircularProgress color="inherit" disableShrink />
          ) : (
            <Button
              variant="contained"
              size="large"
              sx={{
                alignSelf: "center",
                backgroundColor: "transparent",
                border: "2px solid white",
                borderRadius: 10,
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "white",
                  borderColor: "white",
                  color: "#011636",
                  opacity: 0.9,
                },
                "&:disabled": {
                  color: "grey",
                },
              }}
              onClick={handleGenerateVoice}
              disabled={!prompt || !text}
            >
              Generate Voice
            </Button>
          )}
        </>
      )}

      <CustomSnackbar
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        snackbarSeverity={snackbarSeverity}
        snackbarContent={snackbarContent}
      />
    </Stack>
  );
};

export default VoiceSettingsDrawer;
