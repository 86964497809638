import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import CustomizedDialogs from "./CustomizedDilogs";
import DescriptionIcon from "@mui/icons-material/Description";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  getAllChatsByChatId,
  getFileName,
  getSignedUrl,
  deleteAssetsFromProject,
} from "utils/network.js";
import PersonIcon from "@mui/icons-material/Person";
import SideDrawer from "components/Drawers/SideDrawer";

const DocumentContents = () => {
  const [chats, setChats] = useState([]);
  const [urls, setUrls] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const { chatId, folderName } = useParams();
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const toggleSideDrawer = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };

  useEffect(() => {
    async function fetchData() {
      const chat = await getAllChatsByChatId(chatId);
      setChats(chat.data[0].chats);
    }
    async function fetchSignedUrls(docs) {
      const urls = await getSignedUrl(docs);
      return urls;
    }
    async function fetchDocuments() {
      const docs = await getFileName(chatId);
      const urls = await fetchSignedUrls(docs.data.documents);
      setUrls(urls);
    }

    fetchData();
    fetchDocuments();
  }, [chatId]);

  const handleClose = () => {
    setOpen(false);
  };

  const downloadFile = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const hasData = chats.length > 0 || urls.length > 0;

  return (
      <div>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
          sx={{
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}
          className="go-back"
        >
          <Link to={`/c/library/${chatId}`} style={{ color: "#FFFFFF" }}>
            <ArrowBackIcon sx={{ width: "2em", height: "3em" }} />
          </Link>
          <Typography
            variant="h2"
            component="div"
            sx={{ flexGrow: 1, color: "white" }}
          >
            Documents
          </Typography>
          <IconButton
            aria-label="draw"
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 28 },
              color: "white",
              cursor: "pointer",
            }}
            onClick={toggleSideDrawer}
          >
            <PersonIcon />
          </IconButton>
        </Stack>

        <Grid
          container
          spacing={4}
          style={{
            paddingLeft: "2vw", // Adds left margin to the entire grid container
            paddingRight: "2vw",
            height: "calc(100vh - 80px)",
            paddingBottom: "16px",
            overflowY: "auto",
          }}
        >
          {!hasData ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                color: "white",
              }}
            >
              <div>No Data!</div>
            </Grid>
          ) : (
            <>
              {chats.map((chat, index) => (
                <Grid item key={index}>
                  <Button
                    variant="outlined"
                    style={{
                      borderColor: "#FFFFFF",
                      color: "#FFFFFF",
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setOpen(true);
                      setSelectedChat(chat);
                    }}
                  >
                    <DescriptionIcon
                      style={{
                        color: "#FFFFFF",
                        fontSize: "5em",
                        paddingBottom: "10px",
                      }}
                    />
                    Chat {index + 1}
                  </Button>
                  {selectedChat === chat && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: 16,
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "40%",
                        height: "30vh", // Fixed height
                        overflowY: "auto", // Enables vertical scrolling
                        maxHeight: "300px", // Ensures the content doesn't exceed this height
                      }}
                    >
                      <CustomizedDialogs
                        open={open}
                        handleClose={handleClose}
                        content={chat.response}
                      />
                    </div>
                  )}
                </Grid>
              ))}
              {urls.map((url, index) => (
                <Grid item key={index}>
                  <Button
                    variant="outlined"
                    style={{
                      borderColor: "#FFFFFF",
                      color: "#FFFFFF",
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      downloadFile(url);
                    }}
                  >
                    <CloudDownloadIcon
                      style={{
                        color: "#FFFFFF",
                        fontSize: "5em",
                        paddingBottom: "10px",
                      }}
                    />
                    Document
                  </Button>
                </Grid>
              ))}
            </>
          )}
        </Grid>
        <SideDrawer
          isOpen={sideDrawerOpen}
          onOpen={toggleSideDrawer}
          onClose={toggleSideDrawer}
        />
      </div>
  );
};

export default DocumentContents;
