import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getAllChatsByChatId, deleteAssetsFromProject } from "utils/network.js";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import SideDrawer from "components/Drawers/SideDrawer";

const ImageContents = () => {
  const [images, setImages] = useState([]);
  const { chatId, folderName } = useParams();
  const [hoveri, setHover] = useState(false);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const toggleSideDrawer = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };

  useEffect(() => {
    async function fetchData() {
      const chat = await getAllChatsByChatId(chatId);
      const chats = chat.data[0].chats;
      const docImages = chat.data[0].docImageUrls;
      // console.log(chat); // Logging for debugging

      const allImages = [];

      // Process chat images
      chats.forEach((chat) => {
        if (chat.imageUrl) {
          if (Array.isArray(chat.imageUrl)) {
            chat.imageUrl.forEach((img) => {
              if (img.url && img.url !== "") {
                allImages.push(img.url);
              }
            });
          } else if (chat.imageUrl !== "") {
            allImages.push(chat.imageUrl);
          }
        }
      });

      // Process document images
      docImages.forEach((imageData) => {
        if (typeof imageData === "string" && imageData !== "") {
          allImages.push(imageData);
        } else if (imageData.url) {
          if (typeof imageData.url === "string" && imageData.url !== "") {
            allImages.push(imageData.url);
          } else if (imageData.url.url && imageData.url.url !== "") {
            allImages.push(imageData.url.url);
          }
        }
      });

      setImages(allImages);
    }

    fetchData();
  }, [chatId]);

  const handleDelete = async (imageUrl) => {
    const type = "image";
    await deleteAssetsFromProject(imageUrl, chatId, type);
    setImages((prevImages) => prevImages.filter((image) => image !== imageUrl));
  };

  return (
      <div>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
          sx={{
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}
          className="go-back"
        >
          <Link to={`/c/library/${chatId}`} style={{ color: "#FFFFFF" }}>
            <ArrowBackIcon sx={{ width: "2em", height: "3em" }} />
          </Link>
          <Typography
            variant="h2"
            component="div"
            sx={{ flexGrow: 1, color: "white" }}
          >
            Images
          </Typography>
          <IconButton
            aria-label="draw"
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 28 },
              color: "white",
              cursor: "pointer",
            }}
            onClick={toggleSideDrawer}
          >
            <PersonIcon />
          </IconButton>
        </Stack>
        <Grid
          container
          spacing={2}
          style={{
            paddingLeft: "2vw",
            paddingRight: "2vw",
            height: "calc(100vh - 80px)",
            paddingBottom: "16px",
            overflowY: "auto",
          }}
        >
          {images.length === 0 ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                color: "white",
              }}
            >
              <div>No Data!</div>
            </Grid>
          ) : (
            images.map((imageUrl, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Paper
                  elevation={3}
                  style={{ position: "relative", backgroundColor: "#090336" }}
                >
                  <div
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    <IconButton
                      onClick={() => handleDelete(imageUrl)}
                      sx={{
                        zIndex: 1000,
                        position: "absolute",
                        left: 8,
                        top: 8,
                        color: (theme) => theme.palette.common.white,
                        backgroundColor: "rgba(255, 255, 255, 0.2)", // Semi-transparent background
                        border: "1px solid rgba(255, 255, 255, 0.3)", // Light border for a glass effect
                        borderRadius: "50%",
                        // marginLeft: 2,
                        backdropFilter: "blur(10px)", // Blurring the background behind the button
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Optional: Adds subtle shadow
                        transition: "background-color 0.3s, border-color 0.3s", // Smooth transitions
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.3)", // Slightly more opaque on hover
                          borderColor: "rgba(255, 255, 255, 0.5)", // Darker border on hover
                        },
                      }}
                    >
                      <DeleteIcon fontSize="large" />
                    </IconButton>
                  </div>
                  {hoveri ? (
                    <img
                      src={imageUrl}
                      alt={`Image ${index}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        padding: "8px",
                        filter: "blur(5px)", // Apply blur effect
                        transition: "filter 0.3s ease", // Smooth transition for the blur effect
                      }}
                    />
                  ) : (
                    <img
                      src={imageUrl}
                      alt={`Image ${index}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        padding: "8px",
                      }}
                    />
                  )}
                </Paper>
              </Grid>
            ))
          )}
        </Grid>
        <SideDrawer
          isOpen={sideDrawerOpen}
          onOpen={toggleSideDrawer}
          onClose={toggleSideDrawer}
        />
      </div>
  );
};

export default ImageContents;
