import Main from "./Main/Main";
import { FileProvider } from "context/File";
import { UploadSourceProvider } from "context/UploadSource";
import EditorBackground from "components/Background/EditorBackgroundAnimation";

const Home = () => {
  return (
    <UploadSourceProvider>
      <FileProvider>
        <EditorBackground>
          <Main />
        </EditorBackground>
      </FileProvider>
    </UploadSourceProvider>
  );
};

export default Home;
