import { useRef } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CustomModal from "components/Modals/Modal";
import useSnackbar from "hooks/Snackbar";

const ViewPromptModal = ({ promptShow, selectedPrompt, handlePromptClick, setPromptShow }) => {

  const { showSnackbar } = useSnackbar();
  const divRef = useRef(null);
  
  const handleCopyPrompt = () => {
    const divElement = divRef.current;
    if (divElement) {
      const range = document.createRange();
      range.selectNode(divElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      try {
        document.execCommand("copy");
        showSnackbar("success", "Copied to clipboard!");
      } catch (error) {
        showSnackbar("error", "Failed to copy to clipboard");
      } finally {
        window.getSelection().removeAllRanges();
        setPromptShow((prev) => !prev);
      }
    }
  };

  return (
    <CustomModal open={promptShow} handleClose={handlePromptClick}>
      <div ref={divRef}>{selectedPrompt}</div>
      <span
        style={{
          color: "#011636",
          fontSize: "36px",
          padding: "10px 0",
          cursor: "pointer",
        }}
        onClick={handleCopyPrompt}
      >
        <ContentCopyIcon style={{ color: "white", marginTop: "3px" }} />
      </span>
    </CustomModal>
  );
};

export default ViewPromptModal;
