import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { ProvideAuth } from "context/User";
import { UploadSourceProvider } from "context/UploadSource";
import theme from "theme/theme";
import App from "views/App";
import BackgroundAnimation from "components/Background/BackgroundAnimations";
import "./index.css";
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <ThemeProvider theme={theme}>
    <ProvideAuth>
      <UploadSourceProvider>
          <BackgroundAnimation>
            <App />
          </BackgroundAnimation>
      </UploadSourceProvider>
    </ProvideAuth>
  </ThemeProvider>
);
