import { Stack, TextField, Button, Menu, MenuItem } from "@mui/material";
import CustomModal from "components/Modals/Modal";
import MultiImageFileUploader from "components/ImageUploader/multipleImagesUploader";
import PersonaHandler from "components/Drawers/PersonaHandler";
import ImageSettingsDrawer from "components/Drawers/ImageSettingsDrawer";
import ImageFromTextDrawer from "components/Drawers/ImageFromTextDrawer";
import VoiceSettingsDrawer from "components/Drawers/VoiceSettingsDrawer";
import VideoSettingsDrawer from "components/Drawers/VideoSettingsDrawer";
import MusicControllerDrawer from "components/Drawers/MusicControllerDrawer";

// Music settings component
const MusicSettingsModal = ({
  musicDrawer,
  handleMusicDrawertoggle,
  inputValueAIMusic,
  onChangeInputAIMusic,
  handleVolumeChange,
  handleVariationChange,
  handleGenreChange,
  handleMoodChange,
  handleArtistChange,
  handleInstrumentChange,
  getMusic,
  getSoundEffect,
  getSoundEffectFromVideo,
  handleEffectDurationChange,
}) => (
  <CustomModal
    open={musicDrawer}
    handleClose={handleMusicDrawertoggle}
    title="AI Music"
  >
    <MusicControllerDrawer
      inputValue={inputValueAIMusic}
      onChangeInput={onChangeInputAIMusic}
      onVolumeChange={handleVolumeChange}
      onVariationChange={handleVariationChange}
      onGenreChange={handleGenreChange}
      onMoodChange={handleMoodChange}
      onArtistChange={handleArtistChange}
      onInstrumentChange={handleInstrumentChange}
      getMusic={getMusic}
      getSoundEffect={getSoundEffect}
      getSoundEffectFromVideo={getSoundEffectFromVideo}
      onSoundEffectDurationChange={handleEffectDurationChange}
    />
  </CustomModal>
);

// AI writer settings component
const WriterSettingsModal = ({
  promptModalOpen,
  handlePromptModal,
  handlePersonaChange,
  handlePromptGenreChange,
  handleWritingStylesChange,
  handlePersonalityChange,
  handleToneChange,
  handleCategoryChange,
  selectedPersona,
  setPromptForEngine,
  selectedCategory,
  directQuery
}) => (
  <CustomModal
    open={promptModalOpen}
    handleClose={handlePromptModal}
    title="AI Writer"
  >
    <PersonaHandler
      onPersonaChange={handlePersonaChange}
      onGenreChange={handlePromptGenreChange}
      onWritingsChange={handleWritingStylesChange}
      onPersonalityChange={handlePersonalityChange}
      onToneChange={handleToneChange}
      onCategoryChange={handleCategoryChange}
      currentPersona={selectedPersona}
      setNewMessage={setPromptForEngine}
      selectedCategory={selectedCategory}
      onSubmit={directQuery}
    />
  </CustomModal>
);

// Voice settings component
const VoiceSettingsModal = ({
  voiceModalOpen,
  handleVoiceModal,
  voice,
  voiceDisplay,
  anchorEl,
  setAnchorEl,
  open,
  handleClose,
  mappedVoices,
  inputValueAIVoice,
  onChangeInputAIVoice,
  handleSubmitForAIVoice,
  setVoice,
  setVoiceDisplay
}) => (
  <CustomModal
    open={voiceModalOpen}
    handleClose={handleVoiceModal}
    title="AI Voice"
  >
    <VoiceSettingsDrawer
      voice={voice}
      voiceDisplay={voiceDisplay}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      open={open}
      handleClose={handleClose}
      setVoiceDisplay={setVoiceDisplay}
      setVoice={setVoice}
      mappedVoices={mappedVoices}
      inputValue={inputValueAIVoice}
      onChangeInput={onChangeInputAIVoice}
      onSubmit={handleSubmitForAIVoice}
    />
  </CustomModal>
);

// Image settings component
const ImageSettingsModal = ({
  imageSettingModalOpen,
  handleImageSettingsModalClose,
  setImages,
  images,
  uploadSource,
  handleImageAspectRatio,
  handleImageArtStyle,
  handleColorScheme,
  handleImageTone,
  handleSeedValue,
  handlePresetStyle,
  handleCharacterRetention,
  characterRetention,
  upscale,
  outpaint,
  outpaintValues,
  removeBackground,
  handleUpscaleChange,
  handleOutpaintChange,
  handleOutpaintValuesChange,
  handleRemoveBackgroundChange,
  handleDocIllustrationText,
  documentIllustrationText,
  handleImageModelSelection,
  handleTextStyleChange,
  handleSubmitForImageModal
}) => (
  <CustomModal
    open={imageSettingModalOpen}
    handleClose={handleImageSettingsModalClose}
    title="AI Image"
  >
    <MultiImageFileUploader
      setFiles={setImages}
      images={images}
      uploadSource={uploadSource}
    />
    <ImageSettingsDrawer
      images={images}
      onAspectRatioChange={handleImageAspectRatio}
      onImageArtStyleChange={handleImageArtStyle}
      onColoSchemeChange={handleColorScheme}
      onImageToneChange={handleImageTone}
      onImageSeedChange={handleSeedValue}
      onPresetStyleChange={handlePresetStyle}
      onCharacterRetention={handleCharacterRetention}
      characterRetention={characterRetention}
      upscale={upscale}
      outpaint={outpaint}
      outpaintValuesProp={outpaintValues}
      removeBackground={removeBackground}
      onUpscaleChange={handleUpscaleChange}
      onOutpaintChange={handleOutpaintChange}
      onOutpaintValuesChange={handleOutpaintValuesChange}
      onRemoveBackgroundChange={handleRemoveBackgroundChange}
      onChangePromptText={handleDocIllustrationText}
      promptText={documentIllustrationText}
      onModelSelection={handleImageModelSelection}
      onTextStyleChange={handleTextStyleChange}
      onSubmit={handleSubmitForImageModal}
    />
  </CustomModal>
);

// Main AI Controller component
const AIController = (props) => {
  return (
    <div>
      <MusicSettingsModal {...props.musicSettings} />
      <WriterSettingsModal {...props.writerSettings} />
      <VoiceSettingsModal {...props.voiceSettings} />
      <ImageSettingsModal {...props.imageSettings} />

      {/* text to image */}
      <CustomModal
        open={props.imageSettings.imageGeneratorModalOpen}
        handleClose={() =>
          props.imageSettings.setImageGeneratorModalOpen((prevState) => !prevState)
        }
        title="Image Generator"
      >
        <ImageFromTextDrawer
          handleSlowerImageGen={props.imageSettings.handleimagegen}
          handleFasterImageGen={props.imageSettings.handleFastIllustration}
        />
      </CustomModal>

      {/* ai video */}
      <CustomModal
        open={props.videoSettings.videoSettingModalOpen}
        handleClose={props.videoSettings.handleVideoSettingsModalClose}
        title="AI Video"
      >
        <MultiImageFileUploader
          setFiles={props.videoSettings.setVideos}
          images={props.videoSettings.videos}
          uploadSource={props.videoSettings.uploadSource}
        />
        <VideoSettingsDrawer
          onAspectRatioChange={props.videoSettings.handleVideoAspectRatio}
          onMotionStrengthChange={props.videoSettings.handleMotionStrengthChange}
          onVideoModelChange={props.videoSettings.handleselectedVideoMotionModel}
          selectedVideoMotionModel={props.videoSettings.selectedVideoMotionModel}
          onAnimationStyleChange={props.videoSettings.handleAnimationStyle}
          onColorLightingChange={props.videoSettings.handleVideoColorAndLighting}
          onAnimationMoodChange={props.videoSettings.handleAnimationMoods}
          onDirectVideoPromptChange={props.videoSettings.handleAnimationPrompt}
          promptText={props.videoSettings.animationPrompt}
          imagesUploaded={props.videoSettings.videos}
          onSubmit={props.videoSettings.handleSubmitForVideoModel}
        />
      </CustomModal>

      {/* ai document */}
      <CustomModal
        open={props.docIllustrationSettings.showDocIllustratorModal}
        handleClose={props.docIllustrationSettings.handleDocIllustrationClick}
        title="Paste Text for Illustration"
      >
       <Stack direction="column" spacing={3}>
          <TextField
            placeholder="Paste your selection..."
            InputLabelProps={{
              style: { color: "white", fontSize: "14px" },
            }}
            InputProps={{
              style: {
                color: "white",
                fontSize: "16px",
              },
            }}
            sx={{
              "& label.Mui-focused": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              "& .MuiOutlinedInput-input": {
                paddingTop: "16px",
                paddingBottom: "16px",
              },
              "& .MuiInputLabel-outlined": {
                transform: "translate(16px, 16px) scale(1)",
              },
              "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: "translate(16px, -6px) scale(0.75)",
              },
            }}
            onChange={props.imageSettings.handleDocIllustrationText}
            multiline
            maxRows={6}
          />
          <Button
            id="basic-button"
            variant="outlined"
            aria-haspopup="true"
            size="large"
            sx={{
              border: "1px solid white",
              backgroundColor: "transparent",
              color: "white",
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "white",
                color: "white",
                opacity: 0.9,
              },
            }}
            onClick={props.docIllustrationSettings.handleDocIllustration}
          >
            Illustrate
          </Button>
        </Stack>
      </CustomModal>

      {/* ai document narration */}
      <CustomModal
        open={props.docIllustrationSettings.docNarrationModal}
        handleClose={props.docIllustrationSettings.handleDocNarrationClick}
        title="Paste Text for Narration"
      >
        <Stack direction="column" spacing={3}>
          <TextField
            placeholder="5000 characters only..."
            InputLabelProps={{
              style: { color: "white", fontSize: "14px" },
            }}
            InputProps={{
              style: {
                color: "white",
                fontSize: "16px",
              },
            }}
            sx={{
              "& label.Mui-focused": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              "& .MuiOutlinedInput-input": {
                paddingTop: "16px",
                paddingBottom: "16px",
              },
              "& .MuiInputLabel-outlined": {
                transform: "translate(16px, 16px) scale(1)",
              },
              "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: "translate(16px, -6px) scale(0.75)",
              },
            }}
            onChange={props.docIllustrationSettings.handleDocNarrationText}
            multiline
            maxRows={6}
            inputProps={{ maxLength: 5000 }}
          />
          <span>
            {props.generalSettings.startIndex}/{props.generalSettings.endIndex}
          </span>
          {props.generalSettings.startIndex >= 5000 ? (
            <>
              <span style={{ color: "red" }}>
                Please paste 5000 characters at a time
              </span>
            </>
          ) : (
            ""
          )}
          <Button
            id="basic-button"
            variant="outlined"
            aria-haspopup="true"
            size="large"
            onClick={props.generalSettings.handleClick}
            sx={{
              border: "1px solid white",
              backgroundColor: "transparent",
              color: "white",
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "#446597",
                borderColor: "white",
                opacity: 0.9,
              },
            }}
          >
            {props.voiceSettings.voice ? `Voice : ${props.voiceSettings.voiceDisplay}` : "Voices"}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={props.voiceSettings.anchorEl}
            open={props.voiceSettings.open}
            onClose={props.voiceSettings.handleClose}
            MenuListProps={{
              "aria-labelledby": "voice-button",
            }}
            PaperProps={{
              style: {
                backgroundColor: "trasparent",
                color: "#011636",
              },
            }}
          >
            {props.voiceSettings.mappedVoices.map((voice, index) => (
              <MenuItem
                onClick={props.voiceSettings.handleClose}
                key={index}
                id={voice.voice}
                style={{ color: "#011636", fontSize: "16px" }}
              >
                {voice.display}
              </MenuItem>
            ))}
          </Menu>
          <Button
            id="basic-button"
            variant="outlined"
            aria-haspopup="true"
            size="large"
            sx={{
              border: "1px solid white",
              backgroundColor: "transparent",
              color: "white",
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "white",
                color: "white",
                opacity: 0.9,
              },
            }}
            onClick={props.docIllustrationSettings.handleDocNarrate}
          >
            Narrate
          </Button>
        </Stack>
      </CustomModal>
    </div>
  );
};

export default AIController;
