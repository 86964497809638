import { useEffect, useRef } from "react";

const BackgroundAnimation = ({ children }) => {
  const topLeftCircleRef = useRef(null);
  const bottomRightCircleRef = useRef(null);

  useEffect(() => {
    const topLeftCircle = topLeftCircleRef.current;
    const bottomRightCircle = bottomRightCircleRef.current;

    let topLeftCurrentAngle = -75;
    let bottomRightCurrentAngle = 165;

    if (topLeftCircle && bottomRightCircle) {
      topLeftCircle.style.background = `linear-gradient(${topLeftCurrentAngle}deg, #011432 0%, #031023 38%, #A6F8FD 100%)`;
      bottomRightCircle.style.background = `linear-gradient(${bottomRightCurrentAngle}deg, #011432 0%, #F74BB2 88%, #031023 100%)`;
    }

    const animateGradient = (
      element,
      color,
      startAngle,
      endAngle,
      duration
    ) => {
      let startTime;

      const animate = (time) => {
        if (!startTime) startTime = time;
        const timeElapsed = time - startTime;
        const progress = Math.min(timeElapsed / duration, 1);

        const currentAngle = startAngle + (endAngle - startAngle) * progress;
        element.style.background = `linear-gradient(${currentAngle}deg, #011432 0%, ${color} 88%, #031023 100%)`;

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    };

    const startHoverEffect = () => {
      if (topLeftCircle && bottomRightCircle) {
        animateGradient(
          topLeftCircle,
          "#A6F8FD",
          topLeftCurrentAngle,
          -225,
          500
        );
        topLeftCurrentAngle = -225;
        animateGradient(
          bottomRightCircle,
          "#F74BB2",
          bottomRightCurrentAngle,
          -21.68,
          500
        );
        bottomRightCurrentAngle = -21.68;
      }
    };

    const stopHoverEffect = () => {
      if (topLeftCircle && bottomRightCircle) {
        animateGradient(
          topLeftCircle,
          "#A6F8FD",
          topLeftCurrentAngle,
          -75,
          500
        );
        topLeftCurrentAngle = -75;
        animateGradient(
          bottomRightCircle,
          "#F74BB2",
          bottomRightCurrentAngle,
          165,
          500
        );
        bottomRightCurrentAngle = 165;
      }
    };

    const handleCustomEvent = (event) => {
      if (event.detail) {
        startHoverEffect();
      } else {
        stopHoverEffect();
      }
    };

    window.addEventListener("animateGradient", handleCustomEvent);

    return () => {
      window.removeEventListener("animateGradient", handleCustomEvent);
    };
  }, []);

  return (
    <div className="particles-intro">
      <div className="background">
        <div className="circle top-left" ref={topLeftCircleRef}></div>
        <div className="circle bottom-right" ref={bottomRightCircleRef}></div>
        {children}
      </div>
    </div>
  );
};

export default BackgroundAnimation;
