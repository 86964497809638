const EditorBackground = ({ children }) => {
  return (
    <div
      style={{
        background: "#25344e",
        backdropFilter: "blur(50px)",
        WebkitBackdropFilter: "blur(10px)",
        border: "none",
        width: "100vw",
        height: "100vh",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        color: "#FFFFFF",
        position: "fixed",
        opacity: 1,
      }}
    >
      {children}
    </div>
  );
};

export default EditorBackground;