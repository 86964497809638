import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getAllChatsByChatId } from "utils/network";
import Button from "@mui/material/Button";
import CustomizedDialogs from "./CustomizedDilogs";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonIcon from "@mui/icons-material/Person";
import SideDrawer from "components/Drawers/SideDrawer";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
const MusicContents = () => {
  const [narrations, setNarrations] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedNarration, setSelectedNarration] = useState(null);
  const { chatId, folderName } = useParams();
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const toggleSideDrawer = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };
  useEffect(() => {
    async function fetchData() {
      const chatResponse = await getAllChatsByChatId(chatId);
      if (chatResponse.data && chatResponse.data[0]) {
        const chatData = chatResponse.data[0];
        // Ensure the musicUrl is a non-empty string before adding it to the narrations state
        const music =
          typeof chatData.musicUrl.url === "string" &&
          chatData.musicUrl.url.length > 0
            ? [chatData.musicUrl.url]
            : [];
        // console.log(music); // Debugging to see the fetched music URLs
        setNarrations(music);
      }
    }
    fetchData();
  }, [chatId]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (audio) => {
    const type = "music";
    await deleteAssetsFromProject(audio, chatId, type);
    setNarrations((prev) => prev.filter((item) => item !== audio));
  };

  return (
      <div>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
          sx={{
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}
          className="go-back"
        >
          <Link to={`/c/library/${chatId}`} style={{ color: "#FFFFFF" }}>
            <ArrowBackIcon sx={{ width: "2em", height: "3em" }} />
          </Link>
          <Typography
            variant="h2"
            component="div"
            sx={{ flexGrow: 1, color: "white" }}
          >
            Music
          </Typography>
          <IconButton
            aria-label="draw"
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 28 },
              color: "white",
              cursor: "pointer",
            }}
            onClick={toggleSideDrawer}
          >
            <PersonIcon />
          </IconButton>
        </Stack>
        <Grid
          container
          spacing={4}
          style={{
            paddingLeft: "2vw", // Adds left margin to the entire grid container
            paddingRight: "2vw",
            height: "calc(100vh - 80px)",
            paddingBottom: "16px",
            overflowY: "auto",
          }}
        >
          {narrations.length === 0 ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                color: "white",
              }}
            >
              <div>No Data!</div>
            </Grid>
          ) : (
            narrations.map((narration, index) => (
              <Grid item key={index}>
                <Button
                  variant="outlined"
                  style={{
                    borderColor: "#FFFFFF",
                    color: "#FFFFFF",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setOpen(true);
                    setSelectedNarration(narration);
                  }}
                >
                  <VolumeUpIcon
                    style={{
                      color: "#FFFFFF",
                      fontSize: "3em",
                      paddingBottom: "10px",
                    }}
                  />
                  Music {index + 1}
                </Button>
                {selectedNarration === narration && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 16,
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "40%",
                    }}
                  >
                    <CustomizedDialogs
                      open={open}
                      handleClose={handleClose}
                      handleDelete={handleDelete}
                      audioData={narration}
                    />
                  </div>
                )}
              </Grid>
            ))
          )}
        </Grid>
      </div>
  );
};

export default MusicContents;
