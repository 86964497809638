import { Snackbar } from "@mui/material";
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { createPortal } from 'react-dom';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const CustomSnackbar = ({
  snackbarOpen,
  setSnackbarOpen,
  snackbarSeverity,
  snackbarContent,
}) => {

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  return createPortal(
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      TransitionComponent={SlideTransition}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
      }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        sx={{ width: "100%" }}
      >
        <span style={{ fontSize: "18px" }}>{snackbarContent}</span>
      </Alert>
    </Snackbar>,
    document.body
  );
};

export default CustomSnackbar;
