import { useState } from 'react';

const useSnackbar = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [snackbarContent, setSnackbarContent] = useState('');

  const showSnackbar = (severity, content) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setSnackbarContent(content);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);
  };

  return {
    snackbarOpen,
    setSnackbarOpen,
    snackbarSeverity,
    snackbarContent,
    showSnackbar,
  };
};

export default useSnackbar;