import { useState, useCallback } from "react";
import defaultimg from "assets/images/Folder.png";

const glassmorphicStyle = {
  background: "rgba(255, 255, 255, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  borderRadius: "10px",
  background: "rgba(255, 255, 255, 0.1)",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  // backdropFilter: "blur(10px)",
  textAlign: "center",
  color: "white",
};

const MultiImageFileUploader = ({ setFiles, images, uploadSource }) => {
  const [dragging, setDragging] = useState(false);

  const [canvasUploaded, setCanvasUploaded] = useState(false);
  const [predictedImageUploaded, setPredictedImageUploaded] = useState(false);

  const [files, setFilesState] = useState([]);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  }, []);

  const handleDragOver = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!dragging) {
        setDragging(true);
      }
    },
    [dragging]
  );

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
      const droppedFiles = e.dataTransfer.files;
      if (droppedFiles && droppedFiles.length > 0) {
        const imageFiles = Array.from(droppedFiles).filter(
          (file) =>
            file.type.match("image/png") ||
            file.type.match("image/jpeg") ||
            file.type.match("image/webp")
        );
        if (imageFiles.length > 0) {
          // Update parent component's state
          setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
          setFilesState((prevFiles) => [...prevFiles, ...imageFiles]);
        } else {
          alert("Only PNG, JPG, and WEBP files are allowed");
        }
      }
    },
    [setFiles]
  );

  // FIX: Here in future we need to fix this such that if user selects same files state isn't updated
  const handleFileSelect = useCallback(
    (e) => {
      const selectedFiles = e.target.files;
      if (selectedFiles && selectedFiles.length > 0) {
        const imageFiles = Array.from(selectedFiles).filter(
          (file) =>
            file.type.match("image/png") ||
            file.type.match("image/jpeg") ||
            file.type.match("image/webp")
        );
        if (imageFiles.length > 0) {
          // Update parent component's state
          setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
          setFilesState((prevFiles) => [...prevFiles, ...imageFiles]);
        } else {
          alert("Only PNG, JPG, and WEBP files are allowed");
        }
      }
    },
    [setFiles]
  );

  const handleCanvasUpload = async () => {
    try {
      setFiles((prevFiles) => [
        ...prevFiles,
        localStorage.getItem("CanvasImage", "canvas_image.png"),
      ]);
      setTimeout(() => {
        // console.log("Canvas Image Uploaded");
        setCanvasUploaded(true);
      }, 1000); // Simulate 1 second delay
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const preparePredictedImageForUpload = async () => {
    try {
      setFiles((prevFiles) => [
        ...prevFiles,
        localStorage.getItem("CanvasImage", "canvas_image.png"),
      ]);
      setTimeout(() => {
        console.log("Image Uploaded");
        // Update UI to indicate that predicted image is uploaded
        setPredictedImageUploaded(true);
      }, 1000); // Simulate 1 second delay
    } catch (error) {
      console.error("Error uploading predicted image:", error);
    }
  };

  const removeFile = (file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    setFilesState((prevFiles) => prevFiles.filter((f) => f !== file));

    // Reset input element value
    const inputElement = document.getElementById("file-upload");
    if (inputElement) {
      inputElement.value = null;
    }
  };

  const renderUploadComponent = () => {
    switch (uploadSource) {
      case "canvas":
        if (!localStorage.getItem("CanvasImage")) {
          return defaultUploadSection();
        }
        return (
          <div
            className={`dropzone ${dragging ? "dragging" : ""}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={localStorage.getItem("CanvasImage")}
                alt="Canvas Image"
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "350px",
                  maxWidth: "350px",
                  display: "block",
                  margin: "auto",
                }}
              />
              <div style={{ paddingTop: "50px" }}>
                {canvasUploaded ? (
                  <span>Image Uploaded</span>
                ) : (
                  <button
                    onClick={handleCanvasUpload}
                    style={{
                      backgroundColor: "#011636",
                      color: "#FFFFFF",
                      padding: "15px",
                      border: "2px solid #FFFFFF",
                      borderRadius: "10px",
                      cursor: "pointer",
                      margin: "1px", // Adjust margin as needed
                    }}
                  >
                    Upload Image
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      case "predictedImage":
        if (!localStorage.getItem("CanvasImage")) {
          return defaultUploadSection();
        }
        return (
          <div
            className={`dropzone ${dragging ? "dragging" : ""}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={localStorage.getItem("CanvasImage")}
                alt="Predicted"
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "350px",
                  maxWidth: "350px",
                  display: "block",
                  margin: "auto",
                }}
              />
              <div style={{ paddingTop: "10px" }}>
                {predictedImageUploaded ? (
                  <span>Image Uploaded</span>
                ) : (
                  <button
                    onClick={preparePredictedImageForUpload}
                    style={{
                      backgroundColor: "#011636",
                      color: "#FFFFFF",
                      padding: "10px",
                      border: "2px solid #FFFFFF",
                      borderRadius: "5px",
                      cursor: "pointer",
                      margin: "1px", // Adjust margin as needed
                    }}
                  >
                    Upload Image
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      default:
        return defaultUploadSection();
    }
  };

  const defaultUploadSection = () => (
    <div
      className={`dropzone ${dragging ? "dragging" : ""}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      style={{ ...glassmorphicStyle, padding: "20px", margin: "10px" }}
    >
      <div style={{ textAlign: "center" }}>
        {images.length > 0 ? (
          images.map((file, index) => (
            <div
              key={index}
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <span>{file.name}</span>
              <button
                onClick={() => removeFile(file)}
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                  color: "red",
                  border: "none",
                  background: "none",
                }}
              >
                &#x2716;
              </button>
            </div>
          ))
        ) : (
          <div>
            <img
              src={defaultimg}
              alt="Default"
              style={{
                maxHeight: "400px",
                maxWidth: "400px",
                filter: "brightness(0) invert(1)",
              }}
            />
            <div>No image uploaded yet</div>
          </div>
        )}
      </div>
      <div className="ddtext" style={{ marginTop: "15px" }}>
        Drag and drop PNG, JPG, WEBP files here to generate similar content
      </div>
      <div className="ddtext-2" style={{ margin: "10px 0" }}>
        OR
      </div>
      <input
        type="file"
        id="file-upload"
        accept="image/png, image/jpeg, image/webp"
        multiple
        onChange={handleFileSelect}
        // className="ai-button"
        style={{
          ...glassmorphicStyle,
          padding: "10px",
          width: "100%",
        }}
      />
    </div>
  );

  return <div>{renderUploadComponent()}</div>;
};

export default MultiImageFileUploader;
