import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useMediaQuery } from "@mui/material";
import loginVideo from "assets/login_video.MP4";
import logo from "assets/images/Logo.png";
import { useAuth } from "hooks/User";
import CustomSnackbar from "components/Common/Snackbar/Snackbar";
import "./login.css";

const Login = () => {
  const handleHover = (isHovering) => {
    const event = new CustomEvent("animateGradient", { detail: isHovering });
    window.dispatchEvent(event);
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showLoaderAnimation, setShowLoaderAnimation] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  //snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarContent, setSnackbarContent] = useState("");

  const isSmallScreen = useMediaQuery(
    "(max-width: 1200px) and (orientation: portrait)"
  );
  const isShortPhone = useMediaQuery(
    "(max-width: 768px) and (max-height: 730px)"
  );

  const isIpad = useMediaQuery(
    "(min-width: 1000px) and (orientation: portrait)"
  );

  const isLandscape = useMediaQuery(
    "(max-width: 1000px) and (orientation: landscape)"
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  let auth = useAuth();
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const u = queryParams.get("u");
    const p = queryParams.get("p");

    if (u && p && u.length > 0 && p.length > 0) {
      setShowAnimation((prevState) => !prevState);
      login(u, p);
    }
  }, [location.search]);

  const login = async (username, password) => {
    const [status, message] = await auth.login(username, password);
    if (status) {
      console.log("Auth successfull. Redirecting to dashboard");
      navigate("/dashboard");
    } else {
      setShowLoaderAnimation((prev) => !prev);
      setSnackbarSeverity("error");
      setSnackbarContent(message);
      setSnackbarOpen(true);
    }
  };

  const onSubmit = async (data) => {
    setShowLoaderAnimation(true);
    await login(data.username, data.password);
  };

  return (
    <div>
      {isSmallScreen ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <div
            style={{
              zIndex: 5,
              textAlign: "center",
              background:
                "linear-gradient(90deg, #D665AE 2%, #FF52CA 16%, #BA6BDB 33%, #658AEF 55%, #62AFF5 68%, #60CAFA 81%, #5FDBFD 92%, #5FE1FF 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              marginTop: "2vh",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ width: "20vw", marginBottom: "1.5vh" }}
            />
            <h3
              style={{
                fontSize: isIpad ? "5.5vh" : "4vh",
                fontWeight: "900",
                background:
                  "linear-gradient(90deg, #5FE1FF 0%, #5FDBFD 8%, #60CAFA 19%, #62AFF5 32%, #658AEF 45%, #BA6BDB 68%, #FF52CA 84%, #D665AE 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "2px",
                marginBottom: "0vh",
              }}
            >
              Wishtales Compositional AI
            </h3>
            <h5
              style={{
                fontSize: isIpad ? "2vh" : "1.5vh",
                fontWeight: "900",
                background:
                  "linear-gradient(90deg, #5FE1FF 0%, #5FDBFD 8%, #60CAFA 19%, #62AFF5 32%, #658AEF 45%, #BA6BDB 68%, #FF52CA 84%, #D665AE 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginTop: "1vh",
              }}
            >
              Empowering Creators from Ideation to complete Creation with the
              world's first Multimodal AI Copilot.
            </h5>
          </div>
          <div>
            <div style={{ zIndex: 2 }}>
              {videoLoading && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "30vh",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
              <video
                src={loginVideo}
                autoPlay
                loop
                muted
                onLoadStart={() => setVideoLoading(true)}
                onLoadedData={() => setVideoLoading(false)}
                onError={() => setVideoLoading(false)}
                style={{
                  display: videoLoading ? "none" : "block",
                  width: "90vw",
                  borderRadius: 48,
                  objectFit: "cover"
                }}
              />
            </div>
            <div
              style={{
                width: "90vw",
                flex: 1,
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "2vh",
                padding: "2vh 1vw",
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(129.36, 129.36, 129.36, 0.12) 49%, rgba(255, 255, 255, 0.12) 100%)",
                borderRadius: 48,
                boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.10) inset",
                backdropFilter: "blur(100px)",
                textAlign: "center",
              }}
            >
              <h4
                style={{
                  fontWeight: "bold",
                  paddingTop: isShortPhone ? "1.5vh" : "4vh",
                  fontSize: isIpad ? "4vh" : "3vh",
                }}
              >
                Hi, Welcome Back
              </h4>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ width: "90%", paddingTop: "1vh" }}
              >
                <div style={{ marginBottom: "2vh" }}>
                  <label
                    htmlFor="username"
                    style={{
                      fontSize: isIpad ? "2.5vh" : "2vh",
                      display: "block",
                      marginBottom: "1vh",
                      color: "white",
                      textAlign: "left",
                    }}
                  >
                    Email ID
                  </label>
                  <input
                    id="username"
                    type="text"
                    placeholder="Email ID"
                    style={{
                      width: "100%",
                      padding: "10px",
                      margin: "0",
                      borderRadius: "8px",
                      border: "1px solid white",
                      background: "rgba(255, 255, 255, 0.1)",
                      color: "white",
                    }}
                    {...register("username", { required: true })}
                  />
                  {errors.username && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>

                <div style={{ marginBottom: "2vh", position: "relative" }}>
                  <label
                    htmlFor="password"
                    style={{
                      fontSize: isIpad ? "2.5vh" : "2vh",
                      display: "block",
                      marginBottom: "1vh",
                      color: "white",
                      textAlign: "left",
                    }}
                  >
                    Password
                  </label>
                  <div style={{ position: "relative", width: "100%" }}>
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      style={{
                        width: "100%",
                        padding: "10px",
                        paddingRight: "50px", // Space for the icon
                        margin: "0",
                        borderRadius: "8px",
                        border: "1px solid white",
                        background: "rgba(255, 255, 255, 0.1)",
                        color: "white",
                        boxSizing: "border-box",
                      }}
                      {...register("password", { required: true })}
                    />
                    {errors.password && (
                      <span style={{ color: "red" }}>
                        This field is required
                      </span>
                    )}
                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "60%",
                        transform: "translateY(-50%)", // Center vertically
                        cursor: "pointer",
                        color: "white",
                      }}
                    >
                      {showPassword ? (
                        <RemoveRedEyeOutlinedIcon
                          style={{ fontSize: "24px" }}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          style={{ fontSize: "24px" }}
                        />
                      )}
                    </span>
                  </div>
                </div>

                <button
                  type="submit"
                  style={{
                    width: "50%",
                    padding: "0.5vh",
                    borderRadius: "25px",
                    backgroundColor: "transparent",
                    color: "white",
                    fontSize: "3vh",
                    fontWeight: "bold",
                    border: "1px solid rgba(255, 255, 255, 0.5)",
                    cursor: "pointer",
                    transition: "background 0.3s, color 0.3s",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "rgba(255, 255, 255, 0.8)";
                    e.target.style.color = "white";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "transparent";
                    e.target.style.color = "white";
                  }}
                >
                  {showLoaderAnimation ? (
                    <div className="loader"></div>
                  ) : (
                    "Login"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            // background: 'linear-gradient(to right, #081852, #292671)',
            color: "white",
          }}
        >
          <div
            style={{
              zIndex: 5,
              textAlign: "center",
              background:
                "linear-gradient(90deg, #D665AE 2%, #FF52CA 16%, #BA6BDB 33%, #658AEF 55%, #62AFF5 68%, #60CAFA 81%, #5FDBFD 92%, #5FE1FF 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                width: isLandscape ? "10vw" : "6vw",
                marginBottom: "0",
                marginTop: isLandscape ? "2vh" : "2vh",
              }}
            />
            <p
              style={{
                fontSize: isLandscape ? "4.5vw" : "64px",
                fontWeight: "900",
                background:
                  "linear-gradient(90deg, #5FE1FF 0%, #5FDBFD 8%, #60CAFA 19%, #62AFF5 32%, #658AEF 45%, #BA6BDB 68%, #FF52CA 84%, #D665AE 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "2px",
                marginBottom: "0px",
                marginTop: "0px",
              }}
            >
              Wishtales Compositional AI
            </p>
            <h3
              style={{
                fontSize: isLandscape ? "2vw" : "1.5vw",
                fontWeight: "900",
                background:
                  "linear-gradient(90deg, #5FE1FF 0%, #5FDBFD 8%, #60CAFA 19%, #62AFF5 32%, #658AEF 45%, #BA6BDB 68%, #FF52CA 84%, #D665AE 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginTop: "0px",
              }}
            >
              Empowering Creators from Ideation to complete Creation <br />
              with the world's first Multimodal AI Copilot.
            </h3>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              maxWidth: "80vw",
              margin: "auto",
            }}
          >
            <div style={{ zIndex: 2, flex: 1.5, paddingRight: "0px" }}>
              {videoLoading && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50vh",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
              <video
                src={loginVideo}
                autoPlay
                loop
                muted
                onLoadStart={() => setVideoLoading(true)} // Video loading started
                onLoadedData={() => setVideoLoading(false)} // Video loaded
                onError={() => setVideoLoading(false)} // Error loading video
                style={{
                  display: videoLoading ? "none" : "block", // Hide video if loading
                  width: "40vw",
                  height: "55vh",
                  borderRadius: 48,
                  objectFit: "cover", // Ensures the video covers the container
                }}
              />
            </div>
            <div
              style={{
                width: "60vw",
                height: "55vh",
                flex: 1,
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // padding: 'vh 1vw',
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(129.36, 129.36, 129.36, 0.12) 49%, rgba(255, 255, 255, 0.12) 100%)",
                borderRadius: 48,
                boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.10) inset",
                backdropFilter: "blur(100px)",
                textAlign: "center",
                paddingTop: "2vh",
                paddingBottom: "2vh",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  height: "2vh",
                  fontSize: isLandscape ? "2.5vw" : "2vw",
                }}
              >
                Hi, Welcome Back
              </p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ width: "80%", paddingTop: "6vh" }}
              >
                <div
                  style={{
                    marginBottom: isLandscape
                      ? errors.password
                        ? "0vh"
                        : "2vh"
                      : errors.password
                      ? "0.5vh"
                      : "2vh",
                  }}
                >
                  <label
                    htmlFor="username"
                    style={{
                      display: "block",
                      marginBottom: "4vh",
                      color: "white",
                      textAlign: "left",
                      height: "1vh",
                      fontSize: isLandscape ? "1.5vw" : "1.5vw",
                    }}
                  >
                    Email ID
                  </label>
                  <input
                    id="username"
                    type="text"
                    placeholder="Email ID"
                    style={{
                      width: "100%",
                      padding: "15px",
                      marginBottom: isLandscape ? "0.1vh" : "1vh",
                      borderRadius: "8px",
                      border: "none",
                      background: "rgba(255, 255, 255, 0.1)",
                      color: "white",
                      height: "7vh",
                    }}
                    {...register("username", { required: true })}
                  />
                  {errors.username && (
                    <span
                      style={{
                        color: "red",
                        fontSize: isLandscape ? "1.5vw" : "1vw",
                      }}
                    >
                      This field is required
                    </span>
                  )}
                </div>

                <div
                  style={{
                    marginBottom: isLandscape
                      ? errors.password
                        ? "1vh"
                        : "4vh"
                      : errors.password
                      ? "1vh"
                      : "4vh",
                    position: "relative",
                  }}
                >
                  <label
                    htmlFor="password"
                    style={{
                      display: "block",
                      marginBottom: "4vh",
                      color: "white",
                      textAlign: "left",
                      height: "1vh",
                      fontSize: isLandscape ? "1.5vw" : "1.5vw",
                    }}
                  >
                    Password
                  </label>
                  <div style={{ position: "relative", width: "100%" }}>
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      style={{
                        width: "100%",
                        padding: "15px",
                        paddingRight: "50px", // Provide space for the icon
                        marginBottom: "0.5vh",
                        borderRadius: "8px",
                        border: "none",
                        background: "rgba(255, 255, 255, 0.1)",
                        color: "white",
                        boxSizing: "border-box", // Ensure padding doesn't affect width
                        height: "7vh",
                      }}
                      {...register("password", { required: true })}
                    />
                    {errors.password && (
                      <span
                        style={{
                          color: "red",
                          fontSize: isLandscape ? "1.5vw" : "1vw",
                        }}
                      >
                        This field is required
                      </span>
                    )}
                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "15px", // Adjust based on padding
                        top: "50%",
                        transform: "translateY(-50%)", // Center vertically
                        cursor: "pointer",
                        color: "white",
                        zIndex: 2, // Ensure it stays on top
                      }}
                    >
                      {showPassword ? (
                        <RemoveRedEyeOutlinedIcon
                          style={{ fontSize: "24px" }}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          style={{ fontSize: "24px" }}
                        />
                      )}
                    </span>
                  </div>
                </div>

                <button
                  type="submit"
                  style={{
                    width: "50%",
                    padding: "1vh",
                    borderRadius: "8px",
                    backgroundColor: "transparent",
                    color: "white",
                    fontSize: isLandscape ? "1.5vw" : "1.5vw",
                    border: "1px solid rgba(255, 255, 255, 0.5)",
                    borderRadius: "20px",
                    paddingTop: isLandscape ? "0vh" : "1vh",
                    height: "6vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                  }}
                >
                  {showLoaderAnimation ? (
                    <div className="loader"></div>
                  ) : (
                    "Login"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      <CustomSnackbar
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        snackbarSeverity={snackbarSeverity}
        snackbarContent={snackbarContent}
      />
    </div>
  );
};

export default Login;
