import { useState } from "react";
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Stack,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import Folder from "assets/images/Folder.png";
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from "hooks/User";

const SideDrawer = ({ isOpen, onOpen, onClose }) => {
  const auth = useAuth();

  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
          backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(129, 129, 129, 0.2) 49%, rgba(255, 255, 255, 0.2) 100%)",
          backdropFilter: "blur(10px)",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          color: "white",
          width: "20em",
        },
      }}
    >
      <List>
        <ListItem>
          <Typography variant="h4" color="white">Welcome!</Typography>
        </ListItem>
        <ListItem>
          <Link to="library" style={{ textDecoration: "none" }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <img src={Folder} alt="Folder" style={{ width: 24, height: 24, filter: "brightness(0) invert(1)" }} />
              <Typography variant="h6" color="white">
                Library
              </Typography>
            </Stack>
          </Link>
        </ListItem>
        <Divider />
        <ListItem>
          <Link to="/login" onClick={auth.logout} style={{ textDecoration: "none" }}>
            <Stack direction="row" spacing={2} alignItems="center">
            <LogoutIcon style={{ color: "white", fontSize: 24 }} />
              <Typography variant="h6" color="white">
                Logout
              </Typography>
            </Stack>
          </Link>
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
};

export default SideDrawer;