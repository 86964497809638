import { useState, useEffect } from "react";
import {
  List,
  ListItem,
  MenuItem,
  FormControl,
  Select,
  Button,
  Stack,
  Typography,
  TextField,
  Autocomplete,
  InputAdornment,
  Slider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  videoAspectRatios,
  animationStyles,
  animationMoods,
  colorAndLightings,
} from "utils/constants";

const originalAspectRatios = Object.keys(videoAspectRatios);

const VideoSettingsDrawer = ({
  onAspectRatioChange,
  onAnimationStyleChange,
  onMotionStrengthChange,
  onVideoModelChange,
  selectedVideoMotionModel,
  onColorLightingChange,
  onAnimationMoodChange,
  onDirectVideoPromptChange,
  promptText,
  imagesUploaded,
  onSubmit,
}) => {
  const [videoAspectRatio, setVideoAspectRatio] = useState("");
  const [animationStyle, setAnimationStyle] = useState("");
  const [colorAndLighting, setColorAndLighting] = useState("");
  const [animationMood, setAnimationMood] = useState("");
  const [prompt, setPrompt] = useState(promptText);
  const [motionStrength, setMotionStrength] = useState(2);
  const [selectedModel, setSelectedModel] = useState(selectedVideoMotionModel);

  const handleVideoAspectRatio = (event, newvalue) => {
    const mappedValue = videoAspectRatios[newvalue] || newvalue;
    setVideoAspectRatio(mappedValue);
    onAspectRatioChange(mappedValue);
  };

  const handleAnimationStyle = (event, newvalue) => {
    setAnimationStyle(newvalue);
    onAnimationStyleChange(newvalue);
  };

  const handleVideoColorAndLighting = (event, newvalue) => {
    setColorAndLighting(newvalue);
    onColorLightingChange(newvalue);
  };

  const handleAnimationMoods = (event, newvalue) => {
    setAnimationMood(newvalue);
    onAnimationMoodChange(newvalue);
  };

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
    onDirectVideoPromptChange(event);
  };

  const handleMotionStrengthChange = (event, newValue) => {
    setMotionStrength(newValue);
    onMotionStrengthChange(newValue);
  };

  const handleModelSelect = (event) => {
    let selectedModel;
    switch (event.target.id) {
      case "basic-button-1":
        selectedModel = "v1";
        break;
      case "basic-button-2":
        selectedModel = "v2";
        break;
      default:
        selectedModel = "v1";
    }
    if (selectedModel) {
      setSelectedModel(selectedModel);
      onVideoModelChange(selectedModel);
    }
  };

  return (
    <>
      <div style={{ marginTop: "25px" }}>
        <Typography
          variant="h3"
          sx={{
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          Select a Model
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          flexWrap="wrap"
          spacing={{ xs: 1, sm: 1, md: 2 }}
          sx={{ mt: 3, ml: { xs: 0, sm: 0, md: 4, lg: 4, xl: 4 } }}
        >
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              id="basic-button-1"
              variant="outlined"
              aria-haspopup="true"
              size="large"
              sx={{
                border: "1px solid #FFFFFF",
                backgroundColor:
                  selectedModel === "v1" ? "#FFFFFF" : "transparent",
                color: selectedModel === "v1" ? "#011636" : "#FFFFFF",
                fontSize: "16px",
                alignItems: "center",
                textAlign: "center",
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  borderColor: "#FFFFFF",
                  color: "#011636",
                  opacity: 0.9,
                },
                "&.Mui-disabled": {
                  backgroundColor:
                    selectedModel === "v2" ? "#FFFFFF" : "transparent",
                  color: selectedModel === "v2" ? "#011636" : "#FFFFFF",
                  borderColor: "#FFFFFF",
                  opacity: 1,
                  cursor: "not-allowed",
                },
              }}
              onClick={handleModelSelect}
            >
              Video Model 1
            </Button>
            <Typography
              style={{
                color: "#FFFFFF",
                textAlign: "center",
                fontSize: "12px",
                marginTop: "8px",
              }}
            >
              Less detailed, Faster Video Generations upto 5 secs
            </Typography>
          </div>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              id="basic-button-2"
              variant="outlined"
              aria-haspopup="true"
              size="large"
              sx={{
                border: "1px solid #FFFFFF",
                backgroundColor:
                  selectedModel === "v2" ? "#FFFFFF" : "transparent",
                color: selectedModel === "v2" ? "#011636" : "#FFFFFF",
                fontSize: "16px",
                alignItems: "center",
                textAlign: "center",
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  borderColor: "#FFFFFF",
                  color: "#011636",
                  opacity: 0.9,
                },
                "&.Mui-disabled": {
                  backgroundColor:
                    selectedModel === "v2" ? "#FFFFFF" : "transparent",
                  color: selectedModel === "v2" ? "#011636" : "#FFFFFF",
                  borderColor: "#FFFFFF",
                  opacity: 1,
                  cursor: "not-allowed",
                },
              }}
              onClick={handleModelSelect}
            >
              Video model 2
            </Button>
            <Typography
              style={{
                color: "#FFFFFF",
                textAlign: "center",
                fontSize: "12px",
                marginTop: "8px",
              }}
            >
              For Longer & Detailed Generations with perfect Text Animations
            </Typography>
          </div>
        </Stack>
      </div>
      <List
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {( (!imagesUploaded || imagesUploaded.length <= 0) || (imagesUploaded && imagesUploaded.length > 0 && selectedModel === "v2") ) ? (
          <ListItem
            sx={{
              display: "flex",
              alignItems: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "center",
                xl: "center",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "200px",
                  xl: "200px",
                },
                marginRight: {
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "16px",
                  xl: "16px",
                },
              }}
            >
              Prompt *
            </Typography>
            <FormControl fullWidth sx={{ flex: 1 }}>
              <TextField
                label="Prompt"
                variant="outlined"
                value={prompt}
                onChange={handlePromptChange}
                fullWidth
                InputLabelProps={{
                  style: { color: "white", fontSize: "18px" },
                }}
                InputProps={{
                  style: {
                    color: "white",
                    fontSize: "18px",
                  },
                }}
                sx={{
                  "& label.Mui-focused": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    paddingTop: "14px",
                    paddingBottom: "14px",
                  },
                  "& .MuiInputLabel-outlined": {
                    transform: "translate(14px, 14px) scale(1)",
                  },
                  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.75)",
                  },
                }}
                multiline
                maxRows={15}
              />
            </FormControl>
          </ListItem>
        ) : null}
        <ListItem
          sx={{
            display: "flex",
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "center",
              xl: "center",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "200px",
                xl: "200px",
              },
              marginRight: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            Aspect Ratio
          </Typography>
          {imagesUploaded && imagesUploaded.length > 0 ? (
            <Typography variant="h4" sx={{ width: "100%", flex: 1 }}>
              Image's aspect ratio is selected
            </Typography>
          ) : (
            <FormControl fullWidth sx={{ flex: 1 }}>
              <Autocomplete
                // value={Object.keys(videoAspectRatios).find(key => videoAspectRatios[key])}
                onChange={handleVideoAspectRatio}
                options={originalAspectRatios}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            style={{ color: "white", fontSize: "2.5rem" }}
                          />
                        </InputAdornment>
                      ),
                      style: {
                        color: "white",
                        fontSize: "2rem", // Increase the font size of the input text
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white",
                        fontSize: "2rem", // Increase the font size of the placeholder text
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white",
                        },
                      },
                      "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    "& .MuiAutocomplete-option": {
                      fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                      color: "black", // Set text color for the options
                      "&[aria-selected='true']": {
                        backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                      },
                      "&:hover": {
                        backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                      },
                    },
                  },
                }}
              />
            </FormControl>
          )}
        </ListItem>
        {( (!imagesUploaded || imagesUploaded.length <= 0) || (imagesUploaded && imagesUploaded.length > 0 && selectedModel === "v2") ) ? (
          <>
            <ListItem
              sx={{
                display: "flex",
                alignItems: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "flex-start",
                  lg: "center",
                  xl: "center",
                },
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                  xl: "row",
                },
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "200px",
                    xl: "200px",
                  },
                  marginRight: {
                    xs: "0",
                    sm: "0",
                    md: "0",
                    lg: "16px",
                    xl: "16px",
                  },
                }}
              >
                Animation Style
              </Typography>
              <FormControl fullWidth sx={{ flex: 1 }}>
                {/* <Select value={animationStyle} onChange={handleAnimationStyle}>
            {animationStyles.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select> */}
                <Autocomplete
                  value={animationStyle}
                  onChange={handleAnimationStyle}
                  options={animationStyles}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              style={{ color: "white", fontSize: "2.5rem" }}
                            />
                          </InputAdornment>
                        ),
                        style: {
                          color: "white",
                          fontSize: "2rem", // Increase the font size of the input text
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                          fontSize: "2rem", // Increase the font size of the placeholder text
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white",
                          },
                        },
                        "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    />
                  )}
                  ListboxProps={{
                    sx: {
                      "& .MuiAutocomplete-option": {
                        fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                        color: "black", // Set text color for the options
                        "&[aria-selected='true']": {
                          backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                        },
                        "&:hover": {
                          backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                alignItems: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "flex-start",
                  lg: "center",
                  xl: "center",
                },
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                  xl: "row",
                },
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "200px",
                    xl: "200px",
                  },
                  marginRight: {
                    xs: "0",
                    sm: "0",
                    md: "0",
                    lg: "16px",
                    xl: "16px",
                  },
                }}
              >
                Animation Mood
              </Typography>
              <FormControl fullWidth sx={{ flex: 1 }}>
                {/* <Select value={animationMood} onChange={handleAnimationMoods}>
            {animationMoods.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select> */}
                <Autocomplete
                  value={animationMood}
                  onChange={handleAnimationMoods}
                  options={animationMoods}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              style={{ color: "white", fontSize: "2.5rem" }}
                            />
                          </InputAdornment>
                        ),
                        style: {
                          color: "white",
                          fontSize: "2rem", // Increase the font size of the input text
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                          fontSize: "2rem", // Increase the font size of the placeholder text
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white",
                          },
                        },
                        "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    />
                  )}
                  ListboxProps={{
                    sx: {
                      "& .MuiAutocomplete-option": {
                        fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                        color: "black", // Set text color for the options
                        "&[aria-selected='true']": {
                          backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                        },
                        "&:hover": {
                          backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                alignItems: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "flex-start",
                  lg: "center",
                  xl: "center",
                },
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                  xl: "row",
                },
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "200px",
                    xl: "200px",
                  },
                  marginRight: {
                    xs: "0",
                    sm: "0",
                    md: "0",
                    lg: "16px",
                    xl: "16px",
                  },
                }}
              >
                Color Lighting
              </Typography>
              <FormControl fullWidth sx={{ flex: 1 }}>
                {/* <Select
            value={colorAndLighting}
            onChange={handleVideoColorAndLighting}
          >
            {colorAndLightings.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select> */}
                <Autocomplete
                  value={colorAndLighting}
                  onChange={handleVideoColorAndLighting}
                  options={colorAndLightings}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              style={{ color: "white", fontSize: "2.5rem" }}
                            />
                          </InputAdornment>
                        ),
                        style: {
                          color: "white",
                          fontSize: "2rem", // Increase the font size of the input text
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                          fontSize: "2rem", // Increase the font size of the placeholder text
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white",
                          },
                        },
                        "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    />
                  )}
                  ListboxProps={{
                    sx: {
                      "& .MuiAutocomplete-option": {
                        fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                        color: "black", // Set text color for the options
                        "&[aria-selected='true']": {
                          backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                        },
                        "&:hover": {
                          backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </ListItem>
          </>
        ) : null}
        {selectedModel === "v1" &&
        imagesUploaded &&
        imagesUploaded.length > 0 ? (
          <ListItem
            sx={{
              display: "flex",
              alignItems: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "center",
                xl: "center",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "200px",
                  xl: "200px",
                },
                marginRight: {
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "16px",
                  xl: "16px",
                },
              }}
            >
              Motion Strength
            </Typography>
            <FormControl fullWidth sx={{ flex: 1 }}>
              <Slider
                value={motionStrength}
                onChange={handleMotionStrengthChange}
                valueLabelDisplay="auto"
                step={1}
                marks
                min={1}
                max={10}
                defaultValue={3}
                sx={{
                  color: "#FFFFFF",
                  "& .MuiSlider-thumb": {
                    color: "#FFFFFF",
                  },
                  "& .MuiSlider-track": {
                    color: "#FFFFFF",
                  },
                  "& .MuiSlider-rail": {
                    color: "#FFFFFF",
                  },
                }}
              />
            </FormControl>
          </ListItem>
        ) : null}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={{ xs: 1, sm: 1, md: 2 }}
          useFlexGap
          sx={{ marginTop: "16px" }}
        >
          <Button
            id="basic-button"
            variant="outlined"
            aria-haspopup="true"
            size="large"
            style={{
              border: "1px solid white",
              backgroundColor: "transparent",
              color: "white",
              fontSize: "16px",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "white",
                color: "#011636",
              },
            }}
            onClick={onSubmit}
          >
            Animate
          </Button>
        </Stack>
      </List>
    </>
  );
};

export default VideoSettingsDrawer;
