import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog as MuiDialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  styled,
  Menu,
  MenuItem,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import Logo from "assets/images/Logo.png";

const Dialog = styled(MuiDialog)(({ theme, isfullscreen, isSmallScreen }) => ({
  "& .MuiDialog-paper": {
    width: isfullscreen ? "100vw" : (isSmallScreen ? "90vw" : "35vw"),
    height: isfullscreen ? "100vh" : "100vh",
    position: "fixed",
    right: 0,
    top: isfullscreen ? 0 : "40px",
    bottom: isfullscreen ? 0 : "50px",
    margin: isfullscreen ? 0 : "10px",
    borderRadius: isfullscreen ? 0 : "20px",
    overflow: "auto",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
  [`& .MuiSnackbarContent-root`]: {
    background: "rgba(255, 255, 255, 0.1)", // Glass effect background
    backdropFilter: "blur(100px)", // Blur effect
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    color: "#FFFFFF",
    border: "1px solid #FFFFFF",
    fontSize: theme.typography.pxToRem(21),
    maxWidth: 250,
  },
}));

const Toast = ({ open, message, handleClose }) => (
  <CustomSnackbar
    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    open={open}
    autoHideDuration={5000}
    onClose={handleClose}
    message={
      <span>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
        {message}
      </span>
    }
    style={{ marginBottom: "5vh" }}
  />
);


const GlassEffectAppBar = styled(AppBar)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.1)", // Glass effect background
  backdropFilter: "blur(100px)", // Blur effect
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
}));

const Chat = ({
  onSaveToCloud,
  onSave,
  onSaveAsPDF,
  onSaveAsBlob,
  children,
  openi,
  setOpen,
}) => {
  const [openTooltip, setOpenTooltip] = useState(true);
  const [isfullscreen, setisfullscreen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1400);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1400);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const menuOpen = Boolean(anchorEl);

  const [openToast, setOpenToast] = useState(true);

  const handleCloseToast = () => {
    setOpenToast(false);
  };


  useEffect(() => {
    const timer = setTimeout(() => setOpenTooltip(false), 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const toggleFullscreen = () => {
    setisfullscreen(!isfullscreen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (option) => {
    // console.log(`Option ${option} selected`);

    // console.log(typeof option);

    if (option === 1) {
      await onSaveAsPDF();
    } else if (option === 2) {
      await onSave();
    } else if (option === 3) {
      await onSaveAsBlob();
    }

    handleMenuClose();
  };

  return (
    <div>
      <Toast
        open={openToast}
        message="Welcome to Wishtales AI, where your imagination becomes the greatest story ever told!"
        handleClose={handleCloseToast}
      />
      <Button
        variant="contained"
        color="primary"
        style={{
          position: "fixed",
          bottom: "220px",
          right: "30px",
          width: "40px",
          height: "40px",
          background: "rgba(255, 255, 255, 0.1)", // Semi-transparent white background
          backdropFilter: "blur(10px)", // Blur effect
          WebkitBackdropFilter: "blur(10px)", // Blur effect for Safari
          color: "white",
          border: "1px solid black", // Semi-transparent border
          borderRadius: "20px",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", // Optional shadow for depth
        }}
        onClick={handleClick}
      >
        <DownloadIcon
          style={{ fontSize: "20px", color: isSmallScreen ? "black" : "white" }}
        />
      </Button>

      <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
        <MenuItem id="PDF" onClick={() => handleMenuItemClick(1)}>
          PDF
        </MenuItem>
        <MenuItem id="Docx" onClick={() => handleMenuItemClick(2)}>
          Docx
        </MenuItem>
        <MenuItem id="HTML" onClick={() => handleMenuItemClick(3)}>
          HTML
        </MenuItem>
      </Menu>
      <Button
        variant="contained"
        color="primary"
        style={{
          position: "fixed",
          bottom: "170px",
          right: "30px",
          width: "40px",
          height: "40px",
          background: "rgba(255, 255, 255, 0.1)", // Semi-transparent white background
          backdropFilter: "blur(10px)", // Blur effect
          WebkitBackdropFilter: "blur(10px)", // Blur effect for Safari
          color: "white",
          border: "1px solid black", // Semi-transparent border
          borderRadius: "20px",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", // Optional shadow for depth
        }}
        onClick={onSaveToCloud}
      >
        <SaveIcon
          style={{ fontSize: "20px", color: isSmallScreen ? "black" : "white" }}
        />
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{
          position: "fixed",
          bottom: "100px",
          right: "30px",
          background: "transparent",
          width: "60px",
          height: "60px",
          border: "1px solid #FFFFFF",
          borderRadius: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={handleClickOpen}
      >
        <img src={Logo} style={{ width: "50px", height: "50px" }} alt="Chat" />{" "}
      </Button>

      {/* ChatBot Dialog */}
      <Dialog
        open={openi}
        onClose={handleClose}
        TransitionComponent={Transition}
        isfullscreen={isfullscreen}
        isSmallScreen={isSmallScreen}
        PaperProps={{
          sx: {
            backgroundColor: "rgba(0, 22, 54, 0.9)",
            backgroundImage:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(129, 129, 129, 0.1) 49%, rgba(255, 255, 255, 0.2) 100%)",
            backdropFilter: "blur(100px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            color: "white",
            width: "20em",
          },
        }}
      >
        <GlassEffectAppBar position="sticky">
          <Toolbar
            style={{
              color: "#FFFFFF", // White text color
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              style={{ marginLeft: "0" }}
            >
              <CloseIcon style={{ fontSize: "35px" }}/>
            </IconButton>
            <Typography variant="h4" style={{ color: "#FFFFFF" }}>
              Let's Create Together!{" "}
              <WavingHandIcon style={{ fontSize: "20px" }} />
            </Typography>
            <IconButton
              color="inherit"
              onClick={toggleFullscreen}
              aria-label="expand"
              style={{ fontSize: "35px" }} // Increase the size as needed
            >
              <FullscreenIcon style={{ fontSize: "35px" }} />
            </IconButton>
          </Toolbar>
        </GlassEffectAppBar>
        {children}
      </Dialog>
    </div>
  );
};

export default Chat;
