import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getAllChatsByChatId, deleteAssetsFromProject } from "utils/network.js";
import Button from "@mui/material/Button";
import CustomizedDialogs from "./CustomizedDilogs";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Tooltip,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SideDrawer from "components/Drawers/SideDrawer";
import IconButton from "@mui/material/IconButton";

const AnimationContents = () => {
  const [urls, setUrls] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const { chatId, folderName } = useParams();
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const toggleSideDrawer = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };

  useEffect(() => {
    async function fetchData() {
      const chatData = await getAllChatsByChatId(chatId);
      const chats = chatData.data[0].chats;
      const otherAnime = chatData.data[0].docVideoUrl;

      const gatheredUrls = [];

      // Collect all anime URLs
      chats.forEach((chat, index) => {
        if (
          chat.animeUrl &&
          chat.animeUrl.url &&
          chat.animeUrl.url.length > 0
        ) {
          gatheredUrls.push({
            type: "anime",
            url: chat.animeUrl.url,
            index,
            prompt: chat.prompt || "",
          });
        }
        if (chat.videoUrl) {
          chat.videoUrl.forEach((video, videoIndex) => {
            if (video.url && video.url.length > 0) {
              gatheredUrls.push({
                type: "video",
                url: video.url,
                index,
                videoIndex,
                prompt: video.prompt || "",
              });
            }
          });
        }
      });

      // Collect other anime URLs if they are valid
      otherAnime.forEach((item, index) => {
        if (item && item.url.length > 0) {
          gatheredUrls.push({
            type: "other",
            url: item.url,
            index,
            prompt: item.prompt || "",
          });
        }
      });
      setUrls(gatheredUrls);
    }

    fetchData();
  }, [chatId]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (url) => {
    const type = "animation";
    await deleteAssetsFromProject(url, chatId, type);
    setUrls((prevImages) => prevImages.filter((item) => item.url !== url));
  };

  return (
      <div>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
          sx={{
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}
          className="go-back"
        >
          <Link to={`/c/library/${chatId}`} style={{ color: "#FFFFFF" }}>
            <ArrowBackIcon sx={{ width: "2em", height: "3em" }} />
          </Link>
          <Typography
            variant="h2"
            component="div"
            sx={{ flexGrow: 1, color: "white" }}
          >
            Animations
          </Typography>
          <IconButton
            aria-label="draw"
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 28 },
              color: "white",
              cursor: "pointer",
            }}
            onClick={toggleSideDrawer}
          >
            <PersonIcon />
          </IconButton>
        </Stack>
        <Grid
          container
          spacing={4}
          style={{
            paddingLeft: "2vw", // Adds left margin to the entire grid container
            paddingRight: "2vw",
            height: "calc(100vh - 80px)",
            paddingBottom: "16px",
            overflowY: "auto",
          }}
        >
          {urls.length === 0 ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                color: "white",
              }}
            >
              <div>No Data!</div>
            </Grid>
          ) : (
            urls.map((item, idx) => (
              <Grid item key={idx}>
                <Button
                  variant="outlined"
                  style={{
                    borderColor: "white",
                    color: "white",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setOpen(true);
                    setSelectedChat(item);
                    console.log("yes this is url", urls);
                  }}
                >
                  <PlayCircleOutlineIcon
                    style={{
                      color: "#FFFFFF",
                      fontSize: "3em",
                      paddingBottom: "10px",
                    }}
                  />
                  {item.type === "video"
                    ? `Video ${item.index + 1}-${item.videoIndex + 1}`
                    : `Animation ${item.index + 1}`}
                </Button>
                {selectedChat === item && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 16,
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "30%",
                    }}
                  >
                    <CustomizedDialogs
                      open={open}
                      handleClose={handleClose}
                      handleDelete={handleDelete}
                      animeUrl={item.url}
                      prompt={item.prompt}
                    />
                  </div>
                )}
              </Grid>
            ))
          )}
        </Grid>
        <SideDrawer
          isOpen={sideDrawerOpen}
          onOpen={toggleSideDrawer}
          onClose={toggleSideDrawer}
        />
      </div>
  );
};

export default AnimationContents;
