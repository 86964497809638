import React, { useState, useEffect, useContext } from "react";
import {
  Stack,
  useMediaQuery,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import { Link, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Folder from "components/Folders/Folder";
import {
  getAllChats,
  deleteChat,
  renameProject,
  deleteManyProjects,
} from "utils/network";
import { AuthContext } from "context/User";
import { generateUniqueNumber } from "utils/helper";
import SideDrawer from "components/Drawers/SideDrawer";
import PersonIcon from "@mui/icons-material/Person";
import "./Library.css";

const CustomGridItem = styled(Grid)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    flexBasis: "calc(100% / 7.5)", // 7 columns on large screens
    maxWidth: "calc(100% / 7.5)",
  },
  [theme.breakpoints.down("lg")]: {
    flexBasis: "calc(100% / 6.5)", // 6 columns on medium screens
    maxWidth: "calc(100% / 6.5)",
  },
  [theme.breakpoints.down("md")]: {
    flexBasis: "calc(100% / 4)", // 4 columns on small screens
    maxWidth: "calc(100% / 4)",
  },
  [theme.breakpoints.down("sm")]: {
    flexBasis: "calc(100% / 2.5)", // 2 columns on extra small screens
    maxWidth: "calc(100% / 2.5)",
  },
  [theme.breakpoints.down("xs")]: {
    flexBasis: "100%", // 1 column on very small screens
    maxWidth: "100%",
  },
}));

const SelectionCircle = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "-12px",
  right: "-12px",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: "#fff",
  color: "#000",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  fontSize: "14px",
}));

const GlassmorphicIconButton = styled(({ title, selectedFolders, ...props }) => (
  <IconButton {...props} />
))(({ theme, title, selectedFolders }) => ({
  width: '3em',
  height: '3em',
  backgroundColor: 'transparent',
  backgroundImage:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(129, 129, 129, 0.2) 49%, rgba(255, 255, 255, 0.2) 100%)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  // color: 'white',
  // cursor: (title !== "Delete selected" ? (selectedFolders?.length < 1 ? 'pointer' : 'not-allowed') : (selectedFolders?.length === 1 ? 'not-allowed' : 'pointer')),
  cursor: (selectedFolders?.length < 1 ? "not-allowed" : (selectedFolders?.length == 1 ? "pointer" : (title == "Delete selected" ? "pointer" : "not-allowed"))),
  '&.Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Disabled background color
    color: 'rgba(255, 255, 255, 0.5)', // Disabled text color
  },
}));

const Library = () => {
  const { user } = useContext(AuthContext);
  const { chatId } = useParams();
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [touchStartTime, setTouchStartTime] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;
  const isSmallScreen = useMediaQuery(
    "(max-width: 768px) and (orientation: portrait)"
  );
  const isShortPhone = useMediaQuery(
    "(max-width: 768px) and (max-height: 730px)"
  );
  const isLandscape = useMediaQuery(
    "(max-width: 1000px) and (orientation: landscape)"
  );

  const toggleSideDrawer = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const chats = await getAllChats(user.userId);
        setFolders(
          chats.data.map((chat) => ({
            ...chat,
            editableName: chat.projectName
              ? chat.projectName
              : `Project-${chat.clientChatId.slice(-4, -1)}`,
          }))
        );
      } catch (error) {
        console.error("Error fetching chats:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [user.userId]);

  useEffect(() => {
    console.log("folders:", folders);
  }, [folders]);

  const newChatId = user.userId + "t" + generateUniqueNumber();

  const handleRightClick = (event, folder) => {
    event.preventDefault();
    if (selectedFolders.includes(folder)) {
      setSelectedFolders(selectedFolders.filter((item) => item !== folder));
    } else {
      setSelectedFolders([...selectedFolders, folder]);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      const idsToDelete = selectedFolders.map((folder) => folder.clientChatId);
      setFolders(
        folders.filter((folder) => !idsToDelete.includes(folder.clientChatId))
      );

      const response = await deleteManyProjects({
        userId: user.userId,
        chatIds: idsToDelete,
      });

      console.log("response:", response);

      setSelectedFolders([]);
    } catch (error) {
      console.error("Error deleting chats:", error);
    }
  };

  const handleRenameFolder = async (folderId) => {
    try {
      const response = await renameProject({
        userId: user.userId,
        chatId: folderId,
        projectName: newFolderName,
      });
      const updatedFolders = folders.map((folder) => {
        if (folder.clientChatId === folderId) {
          return {
            ...folder,
            name: newFolderName,
            editableName: newFolderName,
          };
        }
        return folder;
      });
      setFolders(updatedFolders);
    } catch (error) {
      console.error("Error renaming chat:", error);
    } finally {
      setSelectedFolders([]);
      setRenameDialogOpen(false);
    }
  };

  const openRenameDialog = () => {
    setRenameDialogOpen(true);
    setNewFolderName(selectedFolders[0].editableName); // assuming folder object has a name property
  };

  const closeRenameDialog = () => {
    setRenameDialogOpen(false);
  };

  const getSelectionIndex = (folder) => selectedFolders.indexOf(folder) + 1;

  const handleLongPressStart = () => {
    setTouchStartTime(Date.now());
  };

  const handleLongPressEnd = (folder) => {
    const touchEndTime = Date.now();
    if (touchEndTime - touchStartTime > 500) {
      handleSelect(folder);
    }
  };

  const handleSelect = (folder) => {
    if (selectedFolders.includes(folder)) {
      setSelectedFolders(selectedFolders.filter((item) => item !== folder));
    } else {
      setSelectedFolders([...selectedFolders, folder]);
    }
  };

  //fixed here

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredFolders = folders.filter((folder) => {
    if (!folder || typeof folder !== "object") return false;

    const lowerCaseQuery = searchQuery.toLowerCase();

    const isMatch = (value) =>
      typeof value === "string" && value.toLowerCase().includes(lowerCaseQuery);

    const searchPromptReplies = (replies) =>
      Array.isArray(replies) &&
      replies.some((reply) => {
        if (!reply || typeof reply !== "object") return false;
        return (
          isMatch(reply.prompt) ||
          isMatch(reply.response) ||
          (Array.isArray(reply.imageUrl) &&
            reply.imageUrl.some((media) => isMatch(media.prompt))) ||
          (Array.isArray(reply.videoUrl) &&
            reply.videoUrl.some((media) => isMatch(media.prompt))) ||
          isMatch(reply.animeUrl?.prompt)
        );
      });

    return (
      isMatch(folder.editableName) ||
      isMatch(folder.projectName) ||
      isMatch(folder.docAudioData?.data) ||
      (Array.isArray(folder.docImageUrls) &&
        folder.docImageUrls.some((media) => isMatch(media.prompt))) ||
      (Array.isArray(folder.docVideoUrl) &&
        folder.docVideoUrl.some((media) => isMatch(media.prompt))) ||
      (Array.isArray(folder.docAudioUrls) &&
        folder.docAudioUrls.some((media) => isMatch(media.url))) ||
      isMatch(folder.musicUrl?.url) ||
      (Array.isArray(folder.artUrls) &&
        folder.artUrls.some((media) => isMatch(media.url))) ||
      searchPromptReplies(folder.chats)
    );
  });

  return (
      <div
        style={{
          overflowY: isLandscape ? "auto" : "hidden",
          height: isLandscape ? "100vh" : "100vh",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          className="go-back"
          padding={2}
          sx={{
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            position: "sticky",
            top: 0,
            zIndex: 1000,
            marginBottom: "1vh", // Add this line to reduce the gap
          }}
        >
          <Link to={`/c/${chatId || newChatId}`}>
            <IconButton aria-label="back">
              <ArrowBackIcon
                style={{ width: "2em", height: "2em", color: "white" }}
              />
            </IconButton>
          </Link>
          <Typography
            variant="h2"
            component="div"
            sx={{ flexGrow: 1, color: "white" }}
          >
            Library
          </Typography>
          <IconButton
            aria-label="draw"
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 28 },
              color: "white",
              cursor: "pointer",
            }}
            onClick={toggleSideDrawer}
          >
            <PersonIcon />
          </IconButton>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
          sx={{ padding: "4px 2px", margin: "8px 4px 0" }}
        >
          {/* Always visible search box */}
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* {selectedFolders.length <= 1 && (
              <> */}
                {(selectedFolders.length < 1 || selectedFolders.length > 1) && (
                  <GlassmorphicIconButton
                    title="Open in editor"
                    aria-label="edit"
                    selectedFolders={selectedFolders}
                    sx={{
                      color: "rgba(0, 0, 0, 0.3)",
                      borderRadius: "20px",
                      minWidth: "3em",
                      height: "2.5em",
                      opacity: "0.7",
                    }}
                    
                  >
                    <Tooltip title="Open in editor">
                      <DriveFileMoveIcon sx={{ width: "2em", height: "2em" }} />
                    </Tooltip>
                  </GlassmorphicIconButton>
                )}
                {selectedFolders.length === 1 && (
                  <Link to={`/c/${selectedFolders[0]?.clientChatId}`}>
                    <GlassmorphicIconButton
                      title="Open in editor"
                      aria-label="edit"
                      selectedFolders={selectedFolders}
                      sx={{
                        color: "white",
                        borderRadius: "20px",
                        minWidth: "3em",
                        height: "2.5em",
                        opacity: "1",
                      }}
                    >
                      <Tooltip title="Open in editor">
                        <DriveFileMoveIcon
                          sx={{ width: "2em", height: "2em" }}
                        />
                      </Tooltip>
                    </GlassmorphicIconButton>
                  </Link>
                )}
                <GlassmorphicIconButton
                  title="Rename folder"
                  onClick={openRenameDialog}
                  selectedFolders={selectedFolders}
                  sx={{
                    color: selectedFolders.length === 1 ? "white" : "rgba(0, 0, 0, 0.3)",
                    borderRadius: "20px",
                    minWidth: "3em",
                    height: "2.5em",
                    opacity: selectedFolders.length === 1 ? "1" : "0.7"
                  }}
                >
                  <Tooltip title="Rename folder">
                    <EditIcon sx={{ width: "2em", height: "2em" }} />
                  </Tooltip>
                </GlassmorphicIconButton>
              {/* </>
            )} */}
            <GlassmorphicIconButton
  title="Delete selected"
  onClick={handleDeleteSelected}
  selectedFolders={selectedFolders}
  sx={{
    color: selectedFolders.length > 0 ? "white" : "rgba(0, 0, 0, 0.3)",
    borderRadius: "20px",
    minWidth: "3em",
    height: "2.5em",
    opacity: selectedFolders.length > 0 ? "1" : "0.7"
  }}
>
  <Tooltip title="Delete selected">
    <DeleteIcon sx={{ width: '2em', height: '2em' }} />
  </Tooltip>
</GlassmorphicIconButton>
          </Stack>
          <TextField
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search the Library here"
            variant="outlined"
            size="small"
            sx={{
              background: "transparent",
              backdropFilter: "blur(10px)",
              WebkitBackdropFilter: "blur(10px)",
              borderRadius: "4px",
              minWidth: "200px",
              marginRight: "16px",
              color: "#FFFFFF",
              width: "100%",
              maxWidth: "350px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.5)",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.7)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(255, 255, 255)",
                },
              },
              "& .MuiInputBase-input": {
                color: "#FFFFFF",
                fontSize: "16px",
              },
              "& .MuiInputLabel-root": {
                color: "#FFFFFF",
                fontSize: "16px",
              },
              "@media (max-width: 600px)": {
                marginRight: "8px",
                minWidth: "150px",
                maxWidth: "100%",
                "& .MuiInputBase-input": {
                  fontSize: "14px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "14px",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <SearchIcon sx={{ color: "rgba(255, 255, 255, 0.7)", mr: 1 }} />
              ),
            }}
            InputLabelProps={{
              shrink: true,
              style: { color: "#FFFFFF" },
            }}
          />
        </Stack>

        <SideDrawer
          isOpen={sideDrawerOpen}
          onOpen={toggleSideDrawer}
          onClose={toggleSideDrawer}
        />

        <div className="folder-structure" style={{ zIndex: 5 }}>
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
          >
            <Grid container justifyContent="center" sx={{ mb: 1 }}>
              <Link
                to={`/c/${newChatId}`}
                style={{
                  color: "white",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <div style={{ cursor: "pointer" }}>
                  <Stack
                    direction="column"
                    spacing={0.2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CreateNewFolderIcon
                      style={{ width: "7em", height: "7em" }}
                    />
                    {"New Project"}
                  </Stack>
                </div>
              </Link>
            </Grid>
            <Grid
              container
              justifyContent= "flex-start"
              
              spacing={{ xs: 2, md: 3 }}
              // padding={1}
              sx={{
                paddingLeft: isSmallScreen ? 0 : 0,
                paddingRight: isSmallScreen ? 0 : 0,
                // margin: 15,
              }}
            >
              {filteredFolders.map((folder, index) => (
                <CustomGridItem
                  item
                  key={index}
                  onContextMenu={(event) => handleRightClick(event, folder)}
                  onTouchStart={isTouchDevice ? handleLongPressStart : null}
                  onTouchEnd={
                    isTouchDevice ? () => handleLongPressEnd(folder) : null
                  }
                  onClick={() => !isTouchDevice && handleSelect(folder)}
                  style={{
                    // width: "calc(100% / 7 - 80px)",
                    margin: "6px",
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ...(selectedFolders.includes(folder) && {
                      background: "rgba(255, 255, 255, 0.1)",
                      backdropFilter: "blur(10px)",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    }),
                  }}
                >
                  {selectedFolders.includes(folder) && (
                    <SelectionCircle>
                      {getSelectionIndex(folder)}
                    </SelectionCircle>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Folder
                      name={folder.editableName}
                      chatId={folder.clientChatId}
                    />
                  </div>
                </CustomGridItem>
              ))}
            </Grid>
          </Grid>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress disableShrink sx={{ color: "white" }} />
            </div>
          ) : null}
          
        </div>
        <Stack
          direction="row"
          alignItems="center"
          padding={2}
          position="relative"
        >
          {/* Container for icons */}
          {isSmallScreen ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              paddingTop={2}
              paddingLeft={10}
              spacing={2}
            >
              <button
                className="select-button"
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderRadius: "20px",
                  marginTop: selectedFolders.length !== 0 ? "2vh" : "4vh", // Add top margin here
                }}
              >
                {selectedFolders.length === 0
                  ? isTouchDevice
                    ? "Select"
                    : "Right click to select"
                  : `Selected (${selectedFolders.length})`}
              </button>
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              padding={2}
              spacing={2}
            >
              <button
                className="select-button"
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderRadius: "20px",
                  marginTop: "10px", // Add top margin here
                }}
              >
                {selectedFolders.length === 0
                  ? isTouchDevice
                    ? "Select"
                    : "Right click to select"
                  : `Selected (${selectedFolders.length})`}
              </button>
            </Stack>
          )}
        </Stack>

        <Dialog
          open={renameDialogOpen}
          onClose={closeRenameDialog}
          PaperProps={{
            sx: {
              background: "rgba(255, 255, 255, 0.1)", // Light translucent background
              backdropFilter: "blur(10px)", // Frosted glass effect
              boxShadow: "0 8px 32px rgba(31, 38, 135, 0.37)", // Soft shadow
              borderRadius: "16px", // Rounded corners
              border: "1px solid rgba(255, 255, 255, 0.18)", // Thin white border
              color: "white", // Text color set to white
            },
          }}
        >
          <DialogTitle sx={{ color: "white" }}>Rename Folder</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: "white" }}>
              Please enter the new name for the selected folder.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="New Folder Name"
              type="text"
              fullWidth
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              sx={{
                background: "rgba(255, 255, 255, 0.15)",
                borderRadius: "8px",
                "& .MuiInputBase-input": {
                  color: "white", // Input text color
                },
                "& .MuiInputLabel-root": {
                  color: "white", // Label text color by default
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "white", // Label text color when focused
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor: "white", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white", // Border color when focused
                  },
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeRenameDialog} sx={{ color: "white" }}>
              Cancel
            </Button>
            <Button
              onClick={() =>
                handleRenameFolder(selectedFolders[0].clientChatId)
              }
              sx={{ color: "white" }}
            >
              Rename
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
};

export default Library;
